import { Get_All_Super_Admin_Settings_List_GET, Get_Details_Setting_Key_GET, Get_Super_Admin_UrlList_GET, Super_Admin_UpdateUrl_PUT, Update_Super_Admin_Settings_PUT } from "../../api/ApiConst.js";
import { FILE_UPLOAD_URL, SETTINGS_URL } from "../CONSTANT.js";
import { SYSTEM_ERROR } from "../Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersdata = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};

export const getSettingsList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SETTINGS_URL() + "list", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingsList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getSuperAdminSettingsList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Get_All_Super_Admin_Settings_List_GET, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingsList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getSuperAdminUrlList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Get_Super_Admin_UrlList_GET, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingsList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getSettingListKey = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SETTINGS_URL() + "listKeys", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingsList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getSettingDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SETTINGS_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getSuperAdminSettingDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Get_Details_Setting_Key_GET.replaceAll("{recordId}", id), { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateSettingKeyValue = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(SETTINGS_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateSettingKeyValue axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateSuperAdminSettingKeyValue = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Update_Super_Admin_Settings_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateSettingKeyValue axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateSuperAdminUrlSettingKeyValue = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Super_Admin_UpdateUrl_PUT.replaceAll("{settingKey}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateSettingKeyValue axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const uploadFile = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_UPLOAD_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in upload file axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
