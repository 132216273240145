import { useRef, useState } from "react";
import PageHeading from "./Common/PageHeading";
import { Agent_Wallet_Commission_GET, All_Number_Of_Game_Played_Count_POST, All_Registered_Player_Count_POST, Get_Agent_Balance, Get_Agent_Total_Player_Count_GET, Get_Dashboard_Count_GET } from "../api/ApiConst";
import { callGetApi, callPostApi } from "../api/ApiCaller";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import moduleUtilities from "../utilities/ModuleUtils";
import Chart from 'react-apexcharts'

const Home = ({ loginData, moduleList }) => {
    const cookies = new Cookies();
    const profile = cookies.get("profile");
    const [dashboardData, setDashboardData] = useState(null);
    // const [balance, setBalance] = useState("");
    const [timeObj, setTimeObj] = useState({
        fromDateTimeStamp: new Date().getTime() - 7 * 24 * 60 * 60 * 1000, toDateTimeStamp: new Date().getTime(),
    });
    const [getRegisteredPlayerCount, setGetRegisteredPlayerCount] = useState(null);
    const [gamePlayedCount, setGamePlayedCount] = useState(null);

    const [registerPlayerCountOptions, setRegisterPlayerCountOptions] = useState({
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
            }
        },
        title: { text: 'Daily Registered Player' }
    });

    const [gameCountOptions, setGameCountOptions] = useState({
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
            }
        },
        title: {
            text: 'Daily Game Played ( Ludo )',
        }
    });

    const [data, setData] = useState(null);
    const [balance, setBalance] = useState("");
    const [CommissionBalance, setCommisionBalance] = useState("");


    const navigate = useNavigate();

    useEffect(() => {
        if (loginData?.userType === "ROLE_ADMIN" || loginData?.userType === "ROLE_SUPER_ADMIN") {
            callGetApi(Get_Dashboard_Count_GET, {}, (response) => {
                setDashboardData(response.responsePacket);
            });
        } else if (loginData?.userType === "ROLE_AGENT") {
            callGetApi(Get_Agent_Total_Player_Count_GET, {}, (response) => {
                setDashboardData(response.responsePacket)
            });
            callGetApi(Get_Agent_Balance, {}, (response) => {
                setBalance(response.responsePacket)

            })
            callGetApi(Agent_Wallet_Commission_GET, {}, (response) => {
                setCommisionBalance(response.responsePacket)
            })
        }
    }, []);

    useEffect(() => {
        getRegisteredPlayerCountDetails();
    }, [timeObj]);

    useEffect(() => {
        getGamePlayedCount();
    }, [moduleList]);

    const getRegisteredPlayerCountDetails = () => {
        if (!(loginData?.userType === "ROLE_ADMIN" || loginData?.userType === "ROLE_SUPER_ADMIN")) {
            return;
        }
        callPostApi(All_Registered_Player_Count_POST.replaceAll("{reportType}", "Daily"), timeObj, {}, (response) => {
            const allDates = calculateMissingDates(timeObj?.fromDateTimeStamp, timeObj?.toDateTimeStamp);
            response.responsePacket.map((item, counter) => {
                allDates.set(item.date, { "playerCount": item.count });
            });

            let finalData = [];
            for (let key of allDates.keys()) {
                finalData.push({
                    "date": key,
                    "playerCount": (allDates.get(key).playerCount !== undefined ? allDates.get(key).playerCount : 0)
                });
            }
            setGetRegisteredPlayerCount(finalData);
        }, (error) => { }
        );
    }

    const getGamePlayedCount = () => {
        if (!(loginData?.userType === "ROLE_ADMIN" || loginData?.userType === "ROLE_SUPER_ADMIN")) {
            return;
        }
            callPostApi(All_Number_Of_Game_Played_Count_POST.replaceAll("{reportType}", "Daily"), timeObj, {}, (response) => {
                const allDates = calculateMissingDates(timeObj?.fromDateTimeStamp, timeObj?.toDateTimeStamp);
                response.responsePacket.map((item, counter) => {
                    allDates.set(item.date, {
                        "playerCount": item.playerCount,
                        "gameCount": item.gameCount,
                        "gameCount_1_INR" : item.gameCount_1_INR,
                        "gameCount_10_INR" : item.gameCount_10_INR,
                        "gameCount_100_INR" : item.gameCount_100_INR,
                        "gameCount_1000_INR" : item.gameCount_1000_INR
                    });
                });
                let finalData = [];
                for (let key of allDates.keys()) {
                    finalData.push({
                        "date": key,
                        "playerCount": (allDates.get(key).playerCount !== undefined ? allDates.get(key).playerCount : 0),
                        "gameCount": (allDates.get(key).gameCount !== undefined ? allDates.get(key).gameCount : 0),
                        "gameCount_1_INR": (allDates.get(key).gameCount_1_INR !== undefined ? allDates.get(key).gameCount_1_INR : 0),
                        "gameCount_10_INR": (allDates.get(key).gameCount_10_INR !== undefined ? allDates.get(key).gameCount_10_INR : 0),
                        "gameCount_100_INR": (allDates.get(key).gameCount_100_INR !== undefined ? allDates.get(key).gameCount_100_INR : 0),
                        "gameCount_1000_INR": (allDates.get(key).gameCount_1_INR !== undefined ? allDates.get(key).gameCount_1000_INR : 0)
                    });
                }
                setGamePlayedCount(finalData);
            }, (error) => { }
            );
    }

    const calculateMissingDates = (fromTimestamp, toTimestamp) => {
        const allDatesMap = new Map();
        const fromDate = new Date(fromTimestamp);
        const toDate = new Date(toTimestamp);
        // Generate an array of date strings within the specified range
        while (fromDate <= toDate) {
            const dateString = fromDate.toISOString().split('T')[0];
            allDatesMap.set(dateString, {});
            fromDate.setDate(fromDate.getDate() + 1);
        }
        return allDatesMap;
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6">
                            {
                                loginData?.userType === "ROLE_ADMIN" || loginData?.userType === "ROLE_SUPER_ADMIN" ?
                                    <div className="row">
                                        <PageHeading heading={"Player Counts"} />
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6" onClick={() => navigate("/players")}>
                                                    <div className="card mini-stats-wid">
                                                        <div className="card-body">
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-3">
                                                                    <p className="text-muted mb-2">Total Players</p>
                                                                    <h5 className="mb-0">{dashboardData?.allPlayerCount}</h5>
                                                                </div>
                                                                <div className="avatar-sm ms-auto">
                                                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                        <i className="bx bx-group" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {dashboardData?.todayPlayerCount ? (
                                                    <div className="col-lg-6" onClick={() => navigate("/players")}>
                                                        <div className="card mini-stats-wid">
                                                            <div className="card-body">
                                                                <div className="d-flex flex-wrap">
                                                                    <div className="me-3">
                                                                        <p className="text-muted mb-2">Today's Player</p>
                                                                        <h5 className="mb-0">{dashboardData?.todayPlayerCount}</h5>
                                                                    </div>
                                                                    <div className="avatar-sm ms-auto">
                                                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                            <i className="bx bx-user" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>

                                        {moduleUtilities.checkModuleIsEnable(moduleList, "KycModule") &&
                                            <>
                                                <PageHeading heading={"KYC Counts"} />
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-lg-6" onClick={() => navigate("/players/Pending")}>
                                                            <div className="card mini-stats-wid">
                                                                <div className="card-body">
                                                                    <div className="d-flex flex-wrap">
                                                                        <div className="me-3">
                                                                            <p className="text-muted mb-2">Pending KYC</p>
                                                                            <h5 className="mb-0">{dashboardData?.kycPendingCount}</h5>
                                                                        </div>
                                                                        <div className="avatar-sm ms-auto">
                                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                                <i className="bx bx-loader" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6" onClick={() => navigate("/players/Approved")}>
                                                            <div className="card blog-stats-wid">
                                                                <div className="card-body">
                                                                    <div className="d-flex flex-wrap">
                                                                        <div className="me-3">
                                                                            <p className="text-muted mb-2">Approved KYC</p>
                                                                            <h5 className="mb-0">{dashboardData?.kycApprovedCount}</h5>
                                                                        </div>
                                                                        <div className="avatar-sm ms-auto">
                                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                                <i className="bx bx-check" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6" onClick={() => navigate("/players/Rejected")}>
                                                            <div className="card blog-stats-wid">
                                                                <div className="card-body">
                                                                    <div className="d-flex flex-wrap">
                                                                        <div className="me-3">
                                                                            <p className="text-muted mb-2">Rejected KYC</p>
                                                                            <h5 className="mb-0">{dashboardData?.kycRejectedCount}</h5>
                                                                        </div>
                                                                        <div className="avatar-sm ms-auto">
                                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                                <i className="bx bx-x-circle" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* <PageHeading heading={"Withdraw Counts"} />
                                        <div className="col-12">
                                            <div className="row">
                                                {
                                                    <div className="col-lg-6" onClick={() => navigate("/withdraw-request")}>
                                                        <div className="card blog-stats-wid">
                                                            <div className="card-body">
                                                                <div className="d-flex flex-wrap">
                                                                    <div className="me-3">
                                                                        <p className="text-muted mb-2">Pending Withdraw</p>
                                                                        <h5 className="mb-0">{dashboardData?.pendingWithdrawRequestCount}</h5>
                                                                    </div>
                                                                    <div className="avatar-sm ms-auto">
                                                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                            <i className="bx bx-loader" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div> */}
                                    </div> : <></>
                            }
                            {
                                loginData?.userType === "ROLE_AGENT" ?
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6" onClick={() => navigate("/agent/players")}>
                                                    <div className="card mini-stats-wid">
                                                        <div className="card-body">
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-3">
                                                                    <p className="text-muted mb-2">Total Players</p>
                                                                    <h5 className="mb-0">{dashboardData && dashboardData}</h5>
                                                                </div>
                                                                <div className="avatar-sm ms-auto">
                                                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                        <i className="bx bx-group" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6" onClick={() => navigate("/agent/walletDetails")}>
                                                    <div className="card mini-stats-wid">
                                                        <div className="card-body">
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-3">
                                                                    <p className="text-muted mb-2">Total Balance</p>
                                                                    <h5 className="mb-0">{balance && balance?.walletBalance.toFixed(2)}</h5>
                                                                </div>
                                                                <div className="avatar-sm ms-auto">
                                                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                        <i className="bx bx-group" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6" onClick={() => navigate("/agent/CommissionDetails")}>
                                                    <div className="card mini-stats-wid">
                                                        <div className="card-body">
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-3">
                                                                    <p className="text-muted mb-2">Total Commission</p>
                                                                    <h5 className="mb-0">{CommissionBalance?.totalCommission}</h5>
                                                                </div>
                                                                <div className="avatar-sm ms-auto">
                                                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                        <i className="bx bx-group" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                        </div>

                        {
                            loginData?.userType === "ROLE_ADMIN" || loginData?.userType === "ROLE_SUPER_ADMIN" ?
                                <div className=" col-xl-6">
                                    {getRegisteredPlayerCount !== null &&
                                        <Chart type='area'
                                            height={300}
                                            series={[{
                                                name: "Registered Player",
                                                data: getRegisteredPlayerCount.map(item => ({ x: (item.date), y: item.playerCount })),
                                                markers: { size: 0 }
                                            }]}
                                            options={registerPlayerCountOptions}
                                        />
                                    }
                                    {gamePlayedCount !== null &&
                                        <Chart type='area'
                                            height={300}
                                            series={[{
                                                name: "Unique Player",
                                                data: gamePlayedCount.map(item => ({ x: (item.date), y: item.playerCount })),
                                                markers: { size: 0 }
                                            }, {
                                                name: "Game Played",
                                                data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount })),
                                                markers: { size: 0 }
                                            },
                                            {
                                                name: "1 INR Games",
                                                data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_1_INR }))
                                            },
                                            {
                                                name: "10 INR Games",
                                                data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_10_INR }))
                                            },
                                            {
                                                name: "100 INR Games",
                                                data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_100_INR}))
                                            },
                                            {
                                                name: "1000 INR Games",
                                                data: gamePlayedCount.map(item => ({ x: (item.date), y: item.gameCount_1000_INR }))
                                            }
                                        ]}
                                            options={
                                                gameCountOptions
                                            } />
                                    }
                                </div>
                                : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;