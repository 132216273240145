import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    getAllPlayerList,
    getCountryList,
    uploadFile,
    updatePlayerDetails,
    getPlayerDetails,
    addPlayer,
    activatePlayer,
    deactivatePlayer,
    deletePlayer,
    walletSendOut,
    walletClaimBack,
    RewardSend,
} from "../../../Services/index";
import { ToastContainer } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ConfirmationDialoge from "../../Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import $ from "jquery";
import { PlayerAgentChange, getAssignAgentDetails, getUnVerifiedNumbers, handleUploadFile, walletWithdrawableBalance } from "../../../Services/MasterServices/PlayerService";
import { All_Player_List_POST, Download_Deposit_Excel_POST, Player_GetSendBonusToPlayersSampleExcel_GET, Player_download_Excel_POST } from "../../../api/ApiConst";
import PaginationDataTable from "../../Common/PaginationDataTable";
import PageHeading from "../../Common/PageHeading";
import dateUtilities from "../../../utilities/DateUtilities";
import moduleUtilities from "../../../utilities/ModuleUtils";
import CustomButton from "../../Common/CustomButton";
import { DateRangePicker } from 'react-date-range';
import { enGB } from 'date-fns/locale';
import { callPostApiForExcelDownLoad } from "../../../api/ApiCaller";
import { toast } from "react-toastify";
import { EXCEL_FILE_UPLOAD_URL } from "../../../Services/CONSTANT";

const filterOptions = [
    {
        label: "All",
        value: "None",
    },
    {
        label: "Pending",
        value: "Pending",
    },
    {
        label: "Approved",
        value: "Approved",
    },
    {
        label: "Rejected",
        value: "Rejected",
    },
];

const modalFilterOptions = [
    {
        value: "All",
        label: "All",
    },
    {
        value: "Rejected",
        label: "Rejected",
    },
    {
        value: "Approved",
        label: "Approved",
    },
    {
        value: "Completed",
        label: "Completed",
    },
    {
        value: "Failed",
        label: "Failed",
    },
];

const PlayerList = ({ moduleList }) => {
    const { filterType } = useParams();
    const navigate = useNavigate();
    // const { state } = useLocation();

    const [show, setShow] = useState({
        addPlayer: false,
        sendClaim: false,
        reward: false,
        withdrawableBalance: false,
        assignAgent: false
    });
    const [amount, setAmount] = useState(1);
    const [remark, setRemark] = useState(null);
    const [rewardAmount, setRewardAmount] = useState(1);
    const [rewardRemark, setRewardRemark] = useState(null);
    const [countryKeyValueList, setCountryKeyValueList] = useState(null);
    const [assignAgentKeyValueList, setAssignAgentKeyValueList] = useState(null);
    const handleSendClaimModal = () => setShow({ ...show, sendClaim: false });
    const handleWithdrawableBalanceModal = () => setShow({ ...show, withdrawableBalance: false });
    const [drawCount, setDrawCount] = useState(0);
    const [agent, setAgent] = useState(null);
    const [country, setCountry] = useState(null);
    const [gender, setGender] = useState(null);
    const [DOB, setDOB] = useState(null);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });
    const [bodyFormFileData, setBodyFormFileData] = useState("");
    const [assetsImageFormData, setAssetsImageFormData] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [filter, setFilter] = useState("");
    const [showExportModal, setShowExportModal] = useState(false);
    const dateRangePickerRef = useRef(null);
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [modalFilter, setModalFilter] = useState(modalFilterOptions[0]);

    const [dateRange, setDateRange] = useState([{
        startDate: new Date(), endDate: new Date(), key: 'selection',
    }]);

    const [showDateRangePicker, setShowDateRangePicker] = useState(false);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleAddPlayerModal = () => {
        setShow({ ...show, addPlayer: false });
        handleClearForm();
    };

    const handleRewardModal = () => setShow({ ...show, reward: false });
    const handleAssignAgentModalClose = () => setShow({ ...show, assignAgent: false });

    useEffect(() => {
        if (filterType) {
            if (filterType === "Pending") {
                setFilter(filterOptions[1]);
            } else if (filterType === "Approved") {
                setFilter(filterOptions[2]);
            } else if (filterType === "Rejected") {
                setFilter(filterOptions[3]);
            }
        } else {
            setFilter(filterOptions[0]);
        }
    }, [filterType]);

    useEffect(() => {
        if (!show.reward) {
            setRewardRemark("");
        }
        if (!show.sendClaim) {
            setRemark("");
        }
    }, [show]);

    const handleNewPlayer = () => {
        setEdit(false);
        setShow({ ...show, addPlayer: true });
    };

    const handleCountry = (e) => {
        setCountry(e);
    };
    const handleChangeAgent = (e) => {
        setAgent(e);
    };

    const handleUserBasic = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };
    const handleGender = (e) => {
        setGender(e);
    };
    const [user, setUser] = useState({
        fullName: "",
        mobileNumber: "",
    });

    const genderOptions = [
        {
            value: "MALE",
            label: "Male",
        },
        {
            value: "FEMALE",
            label: "Female",
        },
    ];

    const handleDeletePlayer = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deletePlayer(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };

    const handlePlayerDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivatePlayer(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };

    const handlePlayerActivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activatePlayer(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const handleDateRange = (ranges) => {
        const selectedDateRange = ranges?.selection;
        if (selectedDateRange && (selectedDateRange.endDate - selectedDateRange.startDate) <= (30 * 24 * 60 * 60 * 1000)) {
            setDateRange([{
                startDate: selectedDateRange.startDate,
                endDate: selectedDateRange.endDate,
                key: 'selection',
            }]);
        } else {
            toast.warn("Please select a date range of 30 days or less", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleExportClick = () => {
        setShowExportModal(true);
        setShowDateRangePicker(true);
    };

    const handleGetUnVerifiedNumbers = () => {
        getUnVerifiedNumbers().then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res.message, { id: "toast" });
            } else {
                handleFailedToast(res?.message, { id: "toast" });
            }
        });
    };

    const [bodyFormFile, setBodyFormFile] = useState("");

    const handleBulkBonusClick = () => {
        setShowBulkModal(true);
        setBodyFormFile("");
        setErrorList([]);
    };

    const handleUpload = (e) => {
        let file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            let base64String = event.target.result.split(",")[1];
            setBodyFormFile({ fileBase64: base64String, fileName: file.name });
        };

        reader.readAsDataURL(file);
    };

    const handleUploadExcel = () => {
        if (bodyFormFile) {
            setShowSpinner(true);
            handleUploadFile(bodyFormFile).then((res) => {
                if (res.errorCode === 0) {
                    setShowSpinner(false);
                    setShowBulkModal(false);
                    handleSuccessToast(res.message);
                } else {
                    // setShowBulkModal(false);
                    setShowSpinner(false);
                    setErrorList(res.listOfErrors)
                    handleFailedToast(res.message);
                }
            });
        }
    };

    const handleSubmit = () => {
        const fromDate = (new Date(dateRange[0]?.startDate.toDateString())).getTime();
        const toDate = (new Date(dateRange[0]?.endDate.toDateString())).getTime();
        const fromDateIst = dateUtilities.timestampToIst(fromDate);
        const toDateIst = dateUtilities.timestampToIst(toDate);
        window.showParentLoader();
        const timeObj = {
            fromDateTimeStamp: fromDateIst,
            toDateTimeStamp: toDateIst,
            kycVerificationStatus: modalFilter.value,
        };

        callPostApiForExcelDownLoad(Player_download_Excel_POST, timeObj, {}, (response) => {
            let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a URL from the Blob
            let url = window.URL.createObjectURL(blob);

            // Create an anchor element and trigger a download
            let link = document.createElement('a');
            link.href = url;

            let currentTimeStamp = new Date().getTime();
            let fileName = `player` +
                `_${dateUtilities.formattedDate(timeObj.fromDateTimeStamp, "ddMMMyyyy")}` +
                `_${dateUtilities.formattedDate(timeObj.toDateTimeStamp, "ddMMMyyyy")}` +
                `_${dateUtilities.formattedDate(currentTimeStamp, "ddMMMyyyy")}` +
                `_${dateUtilities.formatTimeStamp(currentTimeStamp)}` +
                `.csv`;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            handleSuccessToast("CSV file exported successfully.");
            setShowDateRangePicker(false);
            setShowExportModal(false);
            window.hideParentLoader();
            handleRefresh();
        });
    };

    const handleUpdateWallet = (id) => {
        setId(id);
        setShow({
            ...show,
            sendClaim: true,
        });
    };

    const handleUpdateWithdrawableBalance = (data) => {
        setId(data.recordId);
        setShow({
            ...show,
            withdrawableBalance: true,
        });
        setAmount(data.withdrawableBalance);
    };

    const handleReward = (id) => {
        setId(id);
        setShow({
            ...show,
            reward: true,
        });
    };

    const handleSendOut = () => {
        let temp = {
            amount: amount,
            remark: remark,
        };
        if (remark === null) {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        walletSendOut(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + amount + " Send Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleSendClaimModal();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };
    const handleSubmitUpdateWithdrawableBalance = (e) => {
        e.preventDefault();
        let temp = {
            amount: amount,
        };
        walletWithdrawableBalance(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + "Withdrawable Balance Updated Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                setAmount(1);
                handleWithdrawableBalanceModal();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };

    const handleSendReward = () => {
        let temp = {
            amount: rewardAmount,
            remark: rewardRemark,
        };
        if (rewardRemark === null) {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        RewardSend(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + "Reward point added successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleRewardModal();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };
    const handlePlayerChangeAssignAgent = () => {
        if (agent === null) {
            toast.warn("🙁" + "Agent cannot be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        PlayerAgentChange(id, agent?.key).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + "Agent Change successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleAssignAgentModalClose();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };
    const handleAssignAgent = (id) => {
        setId(id);
        setShow({
            ...show,
            assignAgent: true,
        });
    };

    const handleClaimBack = () => {
        let temp = {
            amount: amount,
            remark: remark,
        };
        if (remark === "") {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        walletClaimBack(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + amount + " Claimed Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleSendClaimModal();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };

    //   const search = (rows) => {
    //     return rows.filter((rows) => rows.fullName?.toLowerCase().indexOf(q) > -1);
    //   };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const columns = useMemo(
        () => {
            let playerColumns = [
                {
                    id: "status",
                    name: "",
                    selector: (row) => {
                        if (row.active) {
                            return <div className="led-green" title="Active"></div>;
                        } else {
                            return <div className="led-red" title="Not Active"></div>;
                        }
                    },
                    width: "60px",
                    sortable: false,
                },
                {
                    id: "image",
                    selector: (row, index) => {
                        return <span className="profile-img">{row.profileImageUrl ? <img src={row.profileImageUrl} alt={"User"} /> : <img src="/assets/images/user.png" alt={"User"} />}</span>;
                    },
                    width: "80px",
                    sortable: false,
                },
                {
                    name: "Nick Name",
                    selector: (row) => row.nickName,
                    sortable: true,
                    width: "120px",
                }, {
                    name: "Full Name",
                    selector: (row) => row.fullName,
                    sortable: true,
                    width: "150px",
                },
                {
                    name: "Mobile / Email",
                    selector: (row) => (
                        <a
                            href={"/playerDetail?rId=" + row.recordId}
                            target="_blank"
                            rel="noopener noreferrer"
                            key="t-adminUserDetail"
                        >
                            {
                                row.mobileNumber &&
                                row.countryCode + " " + row.mobileNumber
                            } {
                                (row.mobileNumber && row.email) && <br />
                            } {
                                row.email && row.email
                            }
                        </a>
                    ),
                    sortable: true,
                },
                {
                    name: "Referral",
                    selector: (row) => row.referralCode,
                    sortable: true,
                    width: "120px",
                },
            ];

            if (moduleUtilities.checkModuleIsEnable(moduleList, "KycModule")) {
                playerColumns.push(
                    {
                        name: "KYC",
                        selector: (row) => (
                            <span
                                className={`${row.kycVerificationStatus === "Pending"
                                    ? "badge bg-warning font-size-12"
                                    : row.kycVerificationStatus === "Approved"
                                        ? "badge bg-success font-size-12"
                                        : row.kycVerificationStatus === "Rejected"
                                            ? "badge bg-danger font-size-12"
                                            : ""
                                    }`}
                            >
                                {row.kycVerificationStatus}
                            </span>
                        )
                    }
                );
            }
            playerColumns.push(
                {
                    name: "Balance",
                    selector: (row) => <>{process.env.REACT_APP_CURRENCY_SYMBOL} {row.walletBalance.toFixed(2)}</>,
                    sortable: true,
                    right: true,
                    width: "120px",
                },
                {
                    name: "Registered At",
                    selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
                    sortable: true,
                    width: "145px",
                },
                {
                    name: "Actions",
                    width: "110px",
                    cell: (row) => (
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                <KeyboardArrowDownOutlinedIcon />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? Your want to delete this record",
                                            onConfirm: () => handleDeletePlayer(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Delete
                                    </button>
                                </li>
                                {row.active ? (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are Your Sure? Your want to Deactivate this player",
                                                onConfirm: () => handlePlayerDeactivate(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Deactivate
                                        </button>
                                    </li>
                                ) : (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are Your Sure? Your want to Activate this player",
                                                onConfirm: () => handlePlayerActivate(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Activate
                                        </button>
                                    </li>
                                )}
                                <li onClick={() => handleUpdateWallet(row.recordId)}>
                                    <button className="dropdown-item" type="button">
                                        Send/Claim
                                    </button>
                                </li>
                                {
                                    (moduleUtilities.checkModuleIsEnable(moduleList, "AgentModule")) &&
                                    <li onClick={() => handleAssignAgent(row.recordId)}>
                                        <button className="dropdown-item" type="button">
                                            Assign Agent
                                        </button>
                                    </li>
                                }
                                <li onClick={() => handleReward(row.recordId)}>
                                    <button className="dropdown-item" type="button">
                                        Reward
                                    </button>
                                </li>
                                <li onClick={() => handleUpdateWithdrawableBalance(row)}>
                                    <button className="dropdown-item" type="button">
                                        Update Withdrawable Balance
                                    </button>
                                </li>
                            </ul>
                        </div>
                    ),
                    sortable: true,
                    right: true,
                },
            );
            return playerColumns;
        },
        [moduleList, drawCount, filter]
    );


    const handleImageUpload = (e) => {
        var input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                $("#assetImageImg").attr("src", ev.target.result);
                setAssetsImageFormData(ev.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
        let image = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", image);
        setBodyFormFileData(bodyFormData);
    };

    const validateAddUpdateAssets = () => {
        let inputTag = $("#formrow-inputZip").val();
        if (!(inputTag != "" || ($("#imageId").val() != "" && $("#assetsImageInput").val() != ""))) {
            toast.warn("Please select image first.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    };

    const callAssignAgentAPI = () => {
        getAssignAgentDetails().then((res) => {
            if (res.errorCode === 0) {
                const options =
                    res.responsePacket &&
                    res?.responsePacket?.map((d) => ({
                        key: d.key,
                        value: d.value,
                        label: d.value,
                    }));
                setAssignAgentKeyValueList(options);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };
    const callBasicAPI = () => {
        getCountryList().then((res) => {
            if (res.errorCode === 0) {
                const options =
                    res.responsePacket &&
                    res.responsePacket.map((d) => ({
                        value: d.iso,
                        label: d.name,
                        phoneCode: d.phoneCode,
                    }));
                setCountryKeyValueList(options);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };


    const handleAddPlayer = (e) => {
        e.preventDefault();
        if (validateAddUpdateAssets(e)) {
            let inputTag = $("#formrow-inputZip").val();
            if (inputTag != "") {
                uploadFile(bodyFormFileData).then((res) => {
                    if (res.errorCode === 0) {
                        $("#assetsImageInput").val(res.responsePacket);
                        submitForm();
                    }
                });
            } else {
                submitForm();
            }
        }
    };

    const submitForm = () => {
        let request = {
            fullName: user?.fullName,
            mobileNumber: user?.mobileNumber,
            countryCode: country?.value,
            gender: gender?.value,
            dateOfBirth: DOB,
            profileImageUrl: $("#assetsImageInput").val(),
        };

        if (request.fullName === "" || request.fullName === null) {
            handleWarningToast("Please provide player name.");
            return;
        } else if (request.mobileNumber.length === 0 || request?.mobileNumber === null) {
            handleWarningToast("Mobile Number is Required.");
            return;
        } else if (request.mobileNumber.length != 10 || request?.mobileNumber.length === null) {
            handleWarningToast("Please provide valid mobile number.");
            return;
        } else if (request.countryCode === undefined || request.countryCode === null) {
            handleWarningToast("Please Select Country !");
            return;
        } else if (request.gender === undefined || request.gender === null) {
            handleWarningToast("Please Select Player Gender !");
            return;
        } else if (request.dateOfBirth === undefined || request.dateOfBirth === null) {
            handleWarningToast("Please Select Date Of Birth !");
            return;
        }
        if ($("#imageId").val() != "") {
            updatePlayerDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddPlayerModal();
                    handleClearForm();
                } else {
                    handleFailedToast(res?.message);
                }
            });
        } else {
            addPlayer(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddPlayerModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailedToast(res?.message);
                }
            });
        }
    };

    const handleEditPlayer = (id) => {
        setId(id);
        setShow({ ...show, addPlayer: true });
        setEdit(true);
        getPlayerDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let userDetails = res.responsePacket;
                let dict = {
                    fullName: userDetails.fullName,
                    mobileNumber: userDetails.mobileNumber,
                    profileImageUrl: userDetails.profileImageUrl,
                };
                setUser(dict);
                setDOB(userDetails.dateOfBirth);
                let d = {
                    value: userDetails.gender,
                    label: userDetails.gender,
                };
                setGender(d);
                $("#imageId").val(res.responsePacket.recordId);

                $("#assetsImageInput").val(userDetails.imageUrl);
                // setSelectedImage(userDetails.profileImageUrl)

                getCountryList().then((res) => {
                    if (res.errorCode === 0) {
                        res.responsePacket &&
                            res.responsePacket.map((d) => {
                                if (userDetails.countryCode === d.iso) {
                                    var temp = {
                                        value: d.iso,
                                        label: d.name,
                                        phoneCode: d.phoneCode,
                                    };
                                    setCountry(temp);
                                }
                            });
                    } else if (res.message === "Unauthorised Access") {
                        handleForceLogout(res.message);
                    }
                });
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleFailedToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleDays = (event) => {
        event.preventDefault();
        if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
            setDateRange([{
                startDate: dateRange[0].startDate,
                endDate: dateRange[0].endDate,
                key: 'selection',
            }]);
        }
        setShowDateRangePicker(!showDateRangePicker);
        return false;
    }

    const exportExcelModal = () => {
        return (
            showExportModal && (
                <Modal className="tablerowmodal customModalSize" show={showExportModal} onHide={() => setShowExportModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header>
                        <h4 className="m-0">Export Csv</h4>
                        <Button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowExportModal(false)}></Button>
                    </Modal.Header>
                    <Modal.Body >
                        <form>
                            <div className="row">
                                <div className="col-12 mx-auto" style={{ zIndex: "9" }}>
                                    <div className="custom_dropdown position-relative me-2 top-0 w-100" style={{ left: "0", }}>
                                        <Select options={modalFilterOptions} value={modalFilter}
                                            onChange={(e) => { setModalFilter(e); }} />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <button className='btn border-2 border-dark bg-white w-100'
                                        onClick={(event) => handleDays(event)}>
                                        {dateUtilities.formattedDate(dateRange[0]?.startDate, "dd-MM-yyyy")}
                                        &nbsp; --  &nbsp;
                                        {dateUtilities.formattedDate(dateRange[0]?.endDate, "dd-MM-yyyy")}
                                    </button>
                                    <div className='customDatePikerCount position-relative mt-3' style={{ left: "0" }}>
                                        <div className=' position-relative '>
                                            {showDateRangePicker && (
                                                <>
                                                    <DateRangePicker
                                                        ref={dateRangePickerRef}
                                                        className=''
                                                        ranges={dateRange}
                                                        onChange={(ranges) => {
                                                            handleDateRange(ranges);
                                                        }}
                                                        locale={enGB}
                                                        style={{ top: '100px', left: '50px' }}
                                                    >
                                                    </DateRangePicker>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className=' bg-primary text-white border-0 py-2 px-3 rounded-2 w-100'
                            onClick={handleSubmit}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            )
        );
    };

    const handleBulkBonus = () => {
        return (
            showBulkModal && (
                <Modal className="tablerowmodal customModalSize" show={showBulkModal} onHide={() => setShowBulkModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header>
                        <h4 className="m-0">Bulk Bonus</h4>
                        <Button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowBulkModal(false)}></Button>
                    </Modal.Header>
                    <Modal.Body className="text-center pt-0">
                        <form>
                            <div className="col-lg-12">
                                <div className="mb-3 mt-3" style={{ textAlign: "left" }} >
                                    <div>
                                        <h5 style={{ textAlign: "left", float: "left" }}>Upload Excel</h5>
                                        <Link style={{ float: "right" }} to={Player_GetSendBonusToPlayersSampleExcel_GET}>
                                            <i className="fa fa-download" />
                                            &nbsp; Download Sample
                                        </Link>
                                    </div>
                                    <input type="file" className="form-control" accept=".xls" onChange={(e) => handleUpload(e)} />
                                </div>
                            </div>
                        </form>
                        {errorList.length > 0 && (
                            <div className="mt-3">
                                <h5 style={{ textAlign: "left" }}>Error List</h5>
                                <ul style={{ maxHeight: "280px", overflowY: "scroll", testAlign: "left" }}>
                                    {errorList.map((error, index) => (
                                        <li className="text-start" key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className=' bg-primary text-white border-0 py-2 px-3 rounded-2 w-100'
                            onClick={() => handleUploadExcel()} >
                            {showSpinner ? (
                                <Spinner
                                    disabled={showSpinner}
                                    className="costomLoader mr-2"
                                    animation="border"
                                    size="sm"
                                />
                            ) : (
                                " Upload"
                            )}
                        </button>
                    </Modal.Footer>
                </Modal>
            )
        );
    };

    const handleClearForm = () => {
        setUser({
            fullName: "",
            mobileNumber: "",
            profileImageURL: "",
        });
        setCountry("");
        setGender("");
        setDOB(new Date());
        // setSelectedImage(null)
    };

    useEffect(() => {
        callBasicAPI();
        // callAssignAgentAPI();
    }, []);

    const sendClaimModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.sendClaim} onHide={() => handleSendClaimModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Send And Claim</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleSendClaimModal()}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Enter Amount
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="formrow-email-input"
                                        placeholder="Enter Amount"
                                        min={1}
                                        value={amount}
                                        step={0.01}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Enter Remarks
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="formrow-email-input"
                                        placeholder="Enter Remarks"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary w-md" onClick={handleSendOut}>
                                Send Out
                            </button>
                            &nbsp; &nbsp;
                            <button onClick={handleClaimBack} className="btn btn-danger w-md">
                                Claim Back
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const withdrawableBalanceModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.withdrawableBalance} onHide={() => handleWithdrawableBalanceModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Update Withdrawable Balance</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleWithdrawableBalanceModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Amount
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Amount"
                                            min={1}
                                            value={amount}
                                            step={0.01}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button className="btn btn-primary w-md" onClick={handleSubmitUpdateWithdrawableBalance}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const rewardModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.reward} onHide={() => handleRewardModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Reward</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleRewardModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Amount
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Amount"
                                            value={rewardAmount}
                                            setp={0.01}
                                            onChange={(e) => setRewardAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Remarks
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Remarks"
                                            value={rewardRemark}
                                            onChange={(e) => setRewardRemark(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={() => handleSendReward()}>
                                    Submit
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const assignAgentModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.assignAgent} onHide={() => handleAssignAgentModalClose()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Assign Agent</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAssignAgentModalClose()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Select Agent
                                        </label>
                                        <Select
                                            options={assignAgentKeyValueList}
                                            value={agent}
                                            onChange={(e) => handleChangeAgent(e)}
                                        />

                                    </div>
                                </div>

                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={() => handlePlayerChangeAssignAgent()}>
                                    Submit
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const addPlayerModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addPlayer} onHide={() => handleAddPlayerModal()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Add Player</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddPlayerModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Full Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Full Name"
                                            name="fullName"
                                            value={user.fullName}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Mobile Number
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Mobile Number"
                                            name="mobileNumber"
                                            value={user.mobileNumber}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-password-input" className="form-label">
                                            Select Country
                                        </label>
                                        <Select options={countryKeyValueList} value={country} onChange={(e) => handleCountry(e)} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-password-input" className="form-label">
                                            Select Gender
                                        </label>
                                        <Select options={genderOptions} value={gender} onChange={(e) => handleGender(e)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-password-input" className="form-label">
                                            Select DOB
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="formrow-password-input"
                                            placeholder="Enter Your Password"
                                            onChange={(e) => setDOB(e.target.value)}
                                            value={DOB}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-password-input" className="form-label">
                                            Upload Image
                                        </label>

                                        <input type="hidden" id="imageId" />
                                        <input type="hidden" id="assetsImageInput" />
                                        <img
                                            src={user.profileImageUrl != "" ? user.profileImageUrl : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                                            id="assetImageImg"
                                            style={{
                                                height: "130px",
                                                width: "100%",
                                                objectFit: "contain",
                                                border: "1px solid #7c7979",
                                                padding: "10px",
                                            }}
                                        />
                                        <input type="file" className="form-control" accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAddPlayer}>
                                    {edit ? "Update Player" : "Add Player"}
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddPlayerModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Player List" />
                    <div className="card">
                        {moduleUtilities.checkModuleIsEnable(moduleList, "KycModule") &&
                            <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                                <div className="d-flex export-excel">
                                    <div className="custom_dropdown position-relative me-2 top-0" style={{ left: "0" }} >
                                        <Select
                                            options={filterOptions}
                                            value={filter}
                                            onChange={(e) => {
                                                e.value === "None" ? navigate(`/players`) : navigate(`/players/${e.value}`);
                                                setFilter(e);
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "110px" }}>
                                        <CustomButton title="Export Csv" buttonClass="createButton" handleClick={handleExportClick} />
                                    </div>
                                    <div style={{ width: "103px" }}>
                                        <CustomButton title="Bulk Bonus" buttonClass="createButton" handleClick={handleBulkBonusClick} />
                                    </div>
                                    <div style={{ width: "200px" }}>
                                        <CustomButton title="Get UnVerified numbers" buttonClass="createButton" handleClick={handleGetUnVerifiedNumbers} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="card-body">
                            {filter !== "" && (
                                <PaginationDataTable
                                    paginationUrl={All_Player_List_POST}
                                    serverSidePagination={true}
                                    search={true}
                                    columns={columns}
                                    filter={{ kycVerificationStatus: filter.value }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {show.sendClaim && sendClaimModal()}
            {show.withdrawableBalance && withdrawableBalanceModal()}
            {show.reward && rewardModal()}
            {show.assignAgent && assignAgentModal()}
            {show.addPlayer && addPlayerModal()}
            {exportExcelModal()}
            {handleBulkBonus()}

            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default PlayerList;
