import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Check_Deposit_Request_Details_PUT, Deposit_Request_List_POST, Download_Deposit_Excel_POST, Player_GetPlayerUuidByPlayerId, Update_Deposit_Request_Details_PUT } from "../api/ApiConst.js";
import { callGetApi, callPostApi, callPostApiForExcelDownLoad, callPutApi } from "../api/ApiCaller.js";
import PaginationDataTable from "./Common/PaginationDataTable.jsx";
import { Modal, Spinner } from "react-bootstrap";
import dateUtilities from "../utilities/DateUtilities.jsx";
import PageHeading from "./Common/PageHeading.jsx";
import { ToastContainer } from "react-toastify";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select"
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomButton from "./Common/CustomButton.jsx";
import { DateRangePicker } from 'react-date-range';
import { enGB } from 'date-fns/locale';
import toast from "react-hot-toast";

const paymentStatusOptions = ["Completed", "Failed", "Hold"];

const filterOptions = [
    {
        value: "All",
        label: "All",
    },
    {
        value: "Pending",
        label: "Pending",
    },
    {
        value: "Completed",
        label: "Completed",
    },
    {
        value: "Failed",
        label: "Failed",
    },
    {
        value: "Refunded",
        label: "Refunded",
    },
    {
        value: "Hold",
        label: "Hold",
    },
]

const filterOptionsForModal = [{
    value: "All",
    label: "All",
},
{
    value: "Pending",
    label: "Pending",
},
{
    value: "Completed",
    label: "Completed",
},
{
    value: "Failed",
    label: "Failed",
},
{
    value: "Refunded",
    label: "Refunded",
},
{
    value: "Hold",
    label: "Hold",
},];

const DepositHistory = () => {
    const { filterType } = useParams();
    const navigate = useNavigate();

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [details, setDetails] = useState(null);
    const [currentTxnID, setCurrentTxnID] = useState(null);
    const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
    const [note, setNote] = useState("");
    const [paymentRefID, setPaymentRefID] = useState("");
    const [paymentStatusType, setPaymentStatusType] = useState(paymentStatusOptions[0]);
    const [drawCount, setDrawCount] = useState(0);

    const [filter, setFilter] = useState("");
    const [modalFilter, setModalFilter] = useState(filterOptionsForModal[0]);
    const [showExportModal, setShowExportModal] = useState(false);
    const dateRangePickerRef = useRef(null);
    const [showSpinner, setShowSpinner] = useState(false);

    const [dateRange, setDateRange] = useState([{
        startDate: new Date(), endDate: new Date(), key: 'selection',
    }]);

    const [showDateRangePicker, setShowDateRangePicker] = useState(false);

    useEffect(() => {
        if (filterType) {
            if (filterType === "Pending") {
                setFilter(filterOptions[1]);
            } else if (filterType === "Completed") {
                setFilter(filterOptions[2]);
            } else if (filterType === "Failed") {
                setFilter(filterOptions[3]);
            } else if (filterType === "Refunded") {
                setFilter(filterOptions[4]);
            } else if (filterType === "Hold") {
                setFilter(filterOptions[5]);
            }
        } else {
            setFilter(filterOptions[0]);
        }
    }, [filterType]);

    useEffect(() => {
        if (!showDetailsModal) {
            setDetails(null);
        }
    }, [showDetailsModal]);

    const openPlayerDetail = (id) => {
        let url = Player_GetPlayerUuidByPlayerId.replaceAll("{playerId}", id);
        callGetApi(url, {}, (response) => {
            window.open("/playerDetail?rId=" + response?.responsePacket, '_blank');
        }, (error) => {
            console.log(error);
        });
    };

    const handleRefresh = () => {
        setDrawCount((prev) => ++prev);
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.paymentStatus?.toLowerCase() === "pending" || row.paymentStatus?.toLowerCase() === "hold") {
                        return <div className="led-yellow" title={row.paymentStatus}></div>;
                    } else if (row.paymentStatus?.toLowerCase() === "completed") {
                        return <div className="led-green" title={row.paymentStatus}></div>;
                    } else {
                        return <div className="led-red" title={row.paymentStatus}></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Player Mobile",
                selector: (row) => {
                    if (row.playerMobileNumber === "" || row.playerMobileNumber === undefined) {
                        return <></>;
                    } else {
                        return (
                            <span onClick={() => openPlayerDetail(row.playerId)}
                                style={{ color: '#556ee6', cursor: 'pointer' }}>
                                {"+91 " + row.playerMobileNumber}
                            </span>
                        );
                    }
                },
                sortable: true,
                reorder: true,
            },
            {
                name: "Pan Card Name",
                selector: (row) => row?.panCardName ?? "",
                // selector: (row) => {
                //     const panCardName = row?.panCardName ?? "";
                //     return panCardName.length > 17
                //         ? panCardName.slice(0, 17) + "\n" + panCardName.slice(17)
                //         : panCardName;
                // },
                sortable: true,
                reorder: true,
                // cell: (row) => (
                //     <span style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                //         {row?.panCardName ?? ""}
                //     </span>
                // ),
            },
            {
                name: "Pan Card Number",
                selector: (row) => row?.panCardNumber ?? "",
                sortable: true,
                reorder: true,
            },
            {
                name: "Transaction ID",
                selector: (row) => row.paymentTransactionRefId,
                sortable: true,
                reorder: true,
            },
            {
                name: "Transaction Date",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
                sortable: true,
                reorder: true,
            },
            {
                name: "Payment Gateway",
                selector: (row) => row.paymentGateway,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Requested Amt.",
                selector: (row) => row.requestedAmount?.toFixed(2),
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Amt.",
                selector: (row) => row.amount?.toFixed(2),
                sortable: true,
                right: true,
                reorder: true,
            },
            // {
            //     name: 'Wallet Address',
            //     selector: row => row.cryptoWalletAddress,
            //     sortable: true,
            //     right: true,
            //     reorder: true,
            // },

            // {
            //     name: 'Update At',
            //     selector: (row) => moment(row.modifiedAt).format('DD-MM-YYYY HH:MM A'),
            //     sortable: true,
            //     right: true,
            //     reorder: true,
            // },
            {
                id: "action",
                name: "",
                selector: (row) => {
                    return actionButtons(row);
                },
                width: "140px",
                sortable: false,
            },
        ],
        [drawCount, filter]
    );

    //Action Buttons
    const actionButtons = useCallback((row) => {
        return (
            <div className="d-flex">
                <span className="btn btn-primary mx-1 py-1 px-2 font-size-16" onClick={() => handleViewDetails(row)}>
                    <i className="bx bx-show" />
                </span>
                <div>
                    {(row.paymentStatus?.toLowerCase() === "pending" || row.paymentStatus?.toLowerCase() === "hold") && (
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                <KeyboardArrowDownOutlinedIcon />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li onClick={() => handleCheckStatus(row.paymentTransactionRefId)}>
                                    <button className="dropdown-item" type="button">
                                        Check Status
                                    </button>
                                </li>
                                <li onClick={() => handleUpdateStatus(row.paymentTransactionRefId)}>
                                    <button className="dropdown-item" type="button">
                                        Update status
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        );
    }, []);

    const handleCheckStatus = (recordId) => {
        let url = Check_Deposit_Request_Details_PUT.replaceAll("{paymentTransactionRefId}", recordId);
        callPutApi(
            url,
            {},
            {},
            (response) => {
                if (response.success) {
                    toast.success(response.message);
                    handleRefresh();
                } else {
                    toast.error(response.message);
                    handleRefresh();
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };

    useEffect(() => {
        if (!showUpdateStatusModal) {
            setCurrentTxnID(null);
            setNote("");
            setShowUpdateStatusModal(false);
            setPaymentStatusType(paymentStatusOptions[0]);
        }
    }, [showUpdateStatusModal]);

    const handleSubmitUpdateStatus = () => {
        if (paymentStatusType !== "Completed") {
            if (note === "") {
                toast("Please provide note");
                return;
            }
        }
        let url = Update_Deposit_Request_Details_PUT.replaceAll("{paymentTransactionRefId}", currentTxnID);
        const rObj = {
            note: note,
            paymentStatus: paymentStatusType,
        };
        if (paymentStatusType === "Completed") {
            rObj.paymentGatewayRefId = paymentRefID;
        }
        callPutApi(
            url,
            rObj,
            {},
            (response) => {
                if (response.success) {
                    toast.success(response.message);
                    setCurrentTxnID(null);
                    setNote("");
                    setShowUpdateStatusModal(false);
                    setPaymentStatusType(paymentStatusOptions[0]);
                    handleRefresh();
                } else {
                    toast.error(response.message);
                    handleRefresh();
                }
            },
            (error) => {
                toast.error(error.response.data.message);
                setCurrentTxnID(null);
                setNote("");
                setShowUpdateStatusModal(false);
                setPaymentStatusType(paymentStatusOptions[0]);
            }
        );
    };

    const handleUpdateStatus = (recordId) => {
        setCurrentTxnID(recordId);
        setShowUpdateStatusModal(true);
    };

    const handleViewDetails = (data) => {
        setDetails(data);
        setShowDetailsModal(true);
    };

    const handleDateRange = (ranges) => {
        const selectedDateRange = ranges?.selection;
        if (selectedDateRange && (selectedDateRange.endDate - selectedDateRange.startDate) <= (30 * 24 * 60 * 60 * 1000)) {
            setDateRange([{
                startDate: selectedDateRange.startDate,
                endDate: selectedDateRange.endDate,
                key: 'selection',
            }]);
        } else {
            toast.warn("Please select a date range of 30 days or less", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleExportClick = () => {
        setShowExportModal(true);
        setShowDateRangePicker(true);
    };

    const handleSubmit = () => {
        window.showParentLoader();
        const fromDate = (new Date(dateRange[0]?.startDate.toDateString())).getTime();
        const toDate = (new Date(dateRange[0]?.endDate.toDateString())).getTime();
        const fromDateIst = dateUtilities.timestampToIst(fromDate);
        const toDateIst = dateUtilities.timestampToIst(toDate);
        const timeObj = {
            fromDateTimeStamp: fromDateIst,
            toDateTimeStamp: toDateIst,
            paymentStatus: modalFilter.value
        };
        try {
            callPostApiForExcelDownLoad(Download_Deposit_Excel_POST, timeObj, {}, (response) => {
                // if (response === 0) {
                let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // Create a URL from the Blob
                let url = window.URL.createObjectURL(blob);
                // Create an anchor element and trigger a download
                let link = document.createElement('a');
                link.href = url;

                let currentTimeStamp = new Date().getTime();
                let fileName = `deposit_history` +
                    `_${dateUtilities.formattedDate(timeObj.fromDateTimeStamp, "ddMMMyyyy")}` +
                    `_${dateUtilities.formattedDate(timeObj.toDateTimeStamp, "ddMMMyyyy")}` +
                    `_${dateUtilities.formattedDate(currentTimeStamp, "ddMMMyyyy")}` +
                    `_${dateUtilities.formatTimeStamp(currentTimeStamp)}` +
                    `.csv`;
                link.setAttribute('download', fileName);
                // or any other extension
                document.body.appendChild(link);

                link.click();


                // Clean up and remove the link
                link.parentNode.removeChild(link);

                window.URL.revokeObjectURL(url);
                handleSuccessToast("CSV file exported successfully.");
                setShowDateRangePicker(false);

                setShowExportModal(false);

                setShowSpinner(false);
                // new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
                window.hideParentLoader();
                // });
                handleRefresh();
                // }
            }
            );
        } catch (error) {
            console.log(error);
        }
    };

    const transactionDetailsModal = () => {
        return (
            showDetailsModal && (
                <Modal className="tablerowmodal" show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className="border-bottom d-flex justify-content-between align-items-center pb-3">
                            <h4 className="m-0">Deposit History Details</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsModal(false)}></button>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Transaction ID:</span>
                                <b className="ms-2">{details?.paymentTransactionRefId}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Transaction Status:</span>
                                <b className="ms-2">{details?.paymentStatus}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Payment Captured:</span>
                                <b className="ms-2">{details?.paymentCaptured ? "true" : "false"}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Requested Amount:</span>
                                <b className="ms-2">{details?.requestedAmount}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Amount:</span>
                                <b className="ms-2">{details?.amount}</b>
                            </div>
                            {/* <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Player Nick Name:</span>
                                <b className="ms-2">{details?.playerNickName}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Player Full Name:</span>
                                <b className="ms-2">{details?.playerFullName}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Player Email:</span>
                                <b className="ms-2">{details?.playerEmail}</b>
                            </div> */}
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Player Mobile:</span>
                                <b className="ms-2">{("+91 " + details?.playerMobileNumber) ?? ""}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Pan Card Number:</span>
                                <b className="ms-2">{details?.panCardNumber ?? ""}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Pan Card Name:</span>
                                <b className="ms-2">{details?.panCardName ?? ""}</b>
                            </div>
                            {/* <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Player :</span>
                                <b className="ms-2">{details?.playerCountryCode + " " + details?.playerMobileNumber}</b>
                            </div> */}
                            {details?.paymentGatewayRefId && details?.paymentGatewayRefId !== "" && (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Payment Gateway RefId:</span>
                                    <b className="ms-2">{details?.paymentGatewayRefId}</b>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const updateStatusModal = () => {
        return (
            showUpdateStatusModal && (
                <Modal className="tablerowmodal" show={showUpdateStatusModal} onHide={() => setShowUpdateStatusModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className="border-bottom d-flex justify-content-between align-items-center pb-3">
                            <h4 className="m-0">Update status</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowUpdateStatusModal(false)}></button>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="my-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Status Type
                                        </label>
                                        <select className="form-control" name="" id="" onChange={(e) => setPaymentStatusType(e.target.value)}>
                                            {paymentStatusOptions.map((data) => (
                                                <option value={data}>{data}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {paymentStatusType !== "Completed" && (
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="formrow-email-input" className="form-label">
                                                Enter Note
                                            </label>
                                            <textarea type="text" className="form-control" placeholder="Enter Note" value={note} onChange={(e) => setNote(e.target.value)} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {paymentStatusType === "Completed" && (
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Payment Gateway Ref ID</label>
                                    <div className="input-group auth-pass-inputgroup">
                                        <input type="text" className="form-control" placeholder="Enter Payment Gateway Ref ID" onChange={(e) => setPaymentRefID(e.target.value)} />
                                    </div>
                                </div>
                            )}
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleSubmitUpdateStatus}>
                                    Submit
                                </a>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const handleDays = (event) => {
        event.preventDefault();
        if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
            setDateRange([{
                startDate: dateRange[0].startDate,
                endDate: dateRange[0].endDate,
                key: 'selection',
            }]);
        }
        setShowDateRangePicker(!showDateRangePicker);
        return false;
    }

    const exportExcelModal = () => {
        return (
            showExportModal && (
                <Modal className="tablerowmodal customModalSize" size="md" show={showExportModal} onHide={() => setShowExportModal(false)} aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header>
                        <h4 className="m-0">Export Csv</h4>
                        <Button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowExportModal(false)}></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row">
                                <div className="col-12 mx-auto" style={{ zIndex: "9" }}>
                                    <div className="custom_dropdown position-relative me-2 top-0 w-100" style={{ left: "0", }}>
                                        <Select
                                            menuPosition="relative"
                                            options={filterOptionsForModal}
                                            value={modalFilter}
                                            className="customSelectDrop"
                                            onChange={(e) => {
                                                // e.value === "" ? navigate(`/deposit-history`) : navigate(`/deposit-history/${e.value}`);
                                                setModalFilter(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div>
                                        <button className='btn border-2 border-dark bg-white w-100'

                                            onClick={(event) => handleDays(event)}
                                        >
                                            {dateUtilities.formattedDate(dateRange[0]?.startDate, "dd-MM-yyyy")} &nbsp; --  &nbsp;{
                                                dateUtilities.formattedDate(dateRange[0]?.endDate, "dd-MM-yyyy")}
                                        </button>
                                        <div className='customDatePikerCount position-relative mt-3' style={{ left: "0" }}>
                                            <div className=' position-relative '>
                                                {showDateRangePicker && (
                                                    <>
                                                        <DateRangePicker
                                                            ref={dateRangePickerRef}
                                                            className=''
                                                            ranges={dateRange}
                                                            onChange={(ranges) => {
                                                                handleDateRange(ranges);
                                                            }}
                                                            locale={enGB}
                                                            style={{ top: '100px', left: '50px' }}
                                                        >
                                                        </DateRangePicker>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className=' bg-primary text-white border-0 py-2 px-3 rounded-2 w-100 '
                            onClick={handleSubmit}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            )
        );
    };


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Deposit History"} />
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3">
                                <div className="d-flex export-excel">
                                    <div className="custom_dropdown position-relative me-2 top-0" style={{ left: "0" }}>
                                        <Select
                                            options={filterOptions}
                                            value={filter}
                                            onChange={(e) => {
                                                e.value === "" ? navigate(`/deposit-history`) : navigate(`/deposit-history/${e.value}`);
                                                setFilter(e);
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "200px" }}>
                                        <CustomButton title="Export Csv" buttonClass="createButton" handleClick={handleExportClick} />
                                    </div>
                                </div>
                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <PaginationDataTable
                                        paginationUrl={Deposit_Request_List_POST}
                                        serverSidePagination={true}
                                        drawCount={drawCount} search={true}
                                        columns={columns}
                                        filter={{ paymentStatus: filter.value }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {transactionDetailsModal()}
            {updateStatusModal()}
            {exportExcelModal()}
            <ToastContainer />
        </>
    );
};

export default DepositHistory;
