import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {  deleteAvatarRecord, activateAvatarRecord, deactivateAvatarRecord } from "../Services/index.js";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import DeleteIcon from '@mui/icons-material/Delete'; 
import PageHeading from "./Common/PageHeading.jsx";
import { Admin_Banned_Status_Delete_POST, Admin_Banned_Status_List_POST, Admin_Banned_Status_POST } from "../api/ApiConst.js";
import PaginationDataTable from "./Common/PaginationDataTable.jsx";
import { callDeleteApi, callPostApi } from "../api/ApiCaller.js";
import dateUtilities from "../utilities/DateUtilities.jsx";

const AdminBannedStatus = () => {
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [title, setTitle] = useState("");
    const [drawCount, setDrawCount] = useState(0);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const submitForm = (e) => {
        e.preventDefault();
        if (title === "") {
            toast("Please enter Title");
            return;
        }
        let request = {
            title: title,
        };

        callPostApi(Admin_Banned_Status_POST, request, {}, (response) => {
            if (response.errorCode === 0) {
                toast.success(response.message);
                setTitle("");
                handleRefresh();
            } else {
                toast.error(response.message);
            }
        },
            (error) => {
                console.log(error);
            }
        );
    }

    const handleDeleteRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        callDeleteApi(Admin_Banned_Status_Delete_POST.replaceAll("{uuid}",id), {}, (response) => {
            if (response.errorCode === 0) {
                toast.success(response.message);
                handleRefresh();
            } else {
                toast.error(response.message);
            }
        },
            (error) => {
                console.log(error);
            }
        );
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active"></div>;
                    } else {
                        return <div className="led-red" title="Not Active"></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Title",
                selector: (row) => row.title,
                sortable: true,
                reorder: true,
            },
            {
                id: "createdAt",
                name: "Created At",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
                sortable: false,
                width: "150px",
            },
            {
                name: "Actions",
                cell: (row) => (
                    <div className="">
                        <button className="btn btn-primary p-1" type="button" aria-expanded="false"  onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? Your want to delete this record",
                                        onConfirm: () => handleDeleteRow(row.recordId),
                                    })
                                }>
                        <DeleteIcon  style={{fontSize : "20px"}}/>
                        </button>
                    </div>
                ),
                sortable: true,
                right: true,
                reorder: true,
            },
        ],
        [drawCount]
    );

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Banned States Master"} />
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <form id="configform">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputZip" className="form-label mt-4">
                                                        Title
                                                    </label>
                                                    <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => submitForm(e)}>
                                               Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Banned States List</h4>
                                    <PaginationDataTable
                                        drawCount={drawCount}
                                        paginationUrl={Admin_Banned_Status_List_POST}
                                        serverSidePagination={true}
                                        search={true}
                                        columns={columns}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AdminBannedStatus;
