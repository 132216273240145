import React, { useState, useEffect, useMemo, memo } from "react";
import DataTable from "react-data-table-component";
import * as ApiCaller from "../../api/ApiCaller";
import dateUtilities from "../../utilities/DateUtilities";
import { toast } from "react-toastify";

const PaginationDataTable = (props) => {
    const { filename, dateRange, gameDateRange } = props;
    // npm i react-data-table-component
    // npm i styled-components
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        setLoading(true);
        var jsonRequest = {
            pageNumber: currentPage - 1,
            pageSize: perPage,
            search: filterText,
        };
        if (props.filter && props.filter !== undefined && props.filter !== "") {
            jsonRequest = { ...jsonRequest, ...props.filter };
        }
        ApiCaller.callPostApi(
            props.paginationUrl,
            jsonRequest,
            {},
            (response) => {
                if (props.serverSidePagination) {
                    setData(response.responsePacket.data);
                    setTotalRows(response.responsePacket.recordsTotal);
                } else {
                    setData(response.responsePacket);
                    setTotalRows(response.responsePacket.length);
                }
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                console.log(error);
            }
        );
    }, [props.paginationUrl, props.serverSidePagination, props.columns, perPage, currentPage, filterText,
    props?.filter?.toDateTimeStamp, props?.filter?.fromDateTimeStamp]);
    // TODO > Need to verify this input in datatable.

    const handleChange = (e) => {
        setCurrentPage(1);
        setFilterText(e.target.value);
    };
    const handleClear = () => {
        setFilterText("");
    };

    const subHeaderComponentMemo = () => {
        if (!props.search) {
            return <></>;
        }
        return (
            <>
                <div className={`input-group ${!props.additionalClassName ? "" : props.additionalClassName}`} mb-3 style={{ width: "auto" }}>
                    <input type="text" className="form-control" style={{ paddingLeft: "35px" }} placeholder="Search . . ." value={filterText} onChange={handleChange} />
                    <div className="input-group-prepend" style={{ position: "absolute", height: "100%", zIndex: "4" }}>
                        <span
                            className="input-group-text"
                            style={{
                                padding: "0px",
                                height: "100%",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "transparent",
                                border: "none",
                            }}
                        >
                            <i className="fa fa-search"></i>
                        </span>
                    </div>
                    <div className="input-group-append" onClick={handleClear}>
                        <span
                            className="input-group-text"
                            style={{
                                padding: "0px",
                                height: "100%",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </>
        );
    };

    // convert data into CSV format
    function convertArrayOfObjectsToCSV(csvData) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        let keys = [];
        if (filename === "game_count" || filename === "deposit_count") {
            keys.push("startDate");
            keys.push("endDate");
        }
        keys.push(Object.keys(data[0]));
        keys = keys.flat();

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        csvData?.forEach(item => {
            let ctr = 0;
            keys?.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(csvData, filename) {
        let newCsvData;
        let csv;
        if (filename === 'spin_count') {
            newCsvData = modifyCSVSpinData(csvData);
            csv = convertArrayOfObjectsToCSV(newCsvData);
        } else if (filename === 'deposit_count') {
            newCsvData = modifyCSVDepositData(csvData);
            csv = convertArrayOfObjectsToCSV(newCsvData);
        } else if (filename === "game_count") {
            newCsvData = modifyCSVGameData(csvData);
            csv = convertArrayOfObjectsToCSV(newCsvData);
        } else {
            csv = convertArrayOfObjectsToCSV(csvData);
        }
        const link = document.createElement('a');
        if (csv == null) return;
        const csvUrl = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        link.setAttribute('href', csvUrl);
        link.setAttribute('download', filename);
        link.click();
    }
   
    const modifyCSVSpinData = (csvData) => {
        const modifiedData = csvData.map((row) => {
            const renameMap = {
                threeXCount: '3x',
                fourXCount: '4x',
                sixXCount: '6x',
                tenXCount: '10x',
                hundredXCount: '100x',
                thousandXCount: '1000x',
            };
            Object.keys(renameMap).forEach((oldKey) => {
                if (row.hasOwnProperty(oldKey)) {
                    row[renameMap[oldKey]] = row[oldKey];
                    delete row[oldKey];
                }
            });
            return row;
        });
        return modifiedData;
    }

    const modifyCSVDepositData = (csvData) => {
        const modifiedData = csvData.map((row) => {
            if (row.hasOwnProperty('playerId')) {
                delete row.playerId;
            };
            const newRow = {
                startDate: dateUtilities.formattedDate(dateRange[0]?.startDate, "dd-MM-yyyy"),
                endDate: dateUtilities.formattedDate(dateRange[0]?.endDate, "dd-MM-yyyy"),
                ...row,
            }
            return newRow;
        });
        return modifiedData;
    }

    const modifyCSVGameData = (csvData) => {
        const modifiedData = csvData.map((row) => {
            if (row.hasOwnProperty('playerId')) {
                delete row.playerId;
            };
            const newRow = {
                startDate: dateUtilities.formattedDate(gameDateRange[0]?.startDate, "dd-MM-yyyy"),
                endDate: dateUtilities.formattedDate(gameDateRange[0]?.endDate, "dd-MM-yyyy"),
                ...row,
            }
            return newRow;
        });
        return modifiedData;
    }

    const Export = ({ onExport }) => (
        <button className="btn btn-success me-2" onClick={e => {
            if (data && data.length > 0) {
                onExport(e?.target?.value);
            } else {
                toast.error("No data available for download.");
            }
        }}>
            Download Excel
        </button>
    );

    const exportCSVActionsMemo = React.useMemo(() => <Export onExport={(e) => downloadCSV(data, filename)} />, [data, filename]);

    return props.serverSidePagination ? (
        <DataTable
            className="custom-data-table"
            title=""
            subHeader={props.search}
            subHeaderComponent={subHeaderComponentMemo()}
            columns={props.columns}
            data={data}
            progressPending={loading}
            pagination
            actions={props.exportCSV && exportCSVActionsMemo}
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={(e) => setPerPage(e)}
            onChangePage={(e) => setCurrentPage(e)}
        /* selectableRows onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)} */
        />
    ) : (
        <DataTable
            className="custom-data-table"
            title=""
            columns={props.columns}
            data={data}
            pagination
            actions={props.exportCSV && exportCSVActionsMemo}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
        /* selectableRows onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)} */
        />
    );
};

export default memo(PaginationDataTable);
