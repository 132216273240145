// URLs
const HTTPS = process.env.REACT_APP_HTTPS;
const HOST = process.env.REACT_APP_HOST;
const PORT = process.env.REACT_APP_PORT;
const SERVICE = process.env.REACT_APP_SERVICE;
export const LUDO_SERVER_URL = process.env.REACT_APP_LUDO_SERVER_URL;
export const POKER_SERVER_URL = process.env.REACT_APP_POKER_SERVER_URL;
export const LUDO_SERVER_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const BASE_URL = HTTPS + "://" + HOST + ":" + PORT + SERVICE + "/";

// Dashboard Details
export const Pre_Launch_Data_GET = BASE_URL + "dashboard/getPreLaunchData";
export const Get_Header_Count_GET = BASE_URL + "dashboard/getHeaderCount";
export const Get_Dashboard_Count_GET = BASE_URL + "dashboard/getDashboardCount";

// Player Details
export const Admin_Player_Details_GET = BASE_URL + "player/{playerID}";
export const Player_KYC_Document_List_POST = BASE_URL + "playerDocument/getKYCDocumentList/{playerID}";
export const Delete_Player_KYC_Document_DELETE = BASE_URL + "playerDocument/delete/{playerDocumentId}";
export const Approve_Player_KYC_Document_PUT = BASE_URL + "playerDocument/approve/{documentID}";
export const Reject_Player_KYC_Document_PUT = BASE_URL + "playerDocument/reject/{documentID}";
export const Player_Withdraw_Money_Request_List_POST = BASE_URL + "withdrawRequest/withdrawMoneyRequestList/{playerID}";
export const Player_Deposit_Money_Request_List_POST = BASE_URL + "paymentRequest/list/{playerID}";
export const Approve_Withdraw_Money_Request_PUT = BASE_URL + "withdrawRequest/approveRequest/{withdrawTransactionID}";
export const Reject_Withdraw_Money_Request_PUT = BASE_URL + "withdrawRequest/rejectRequest/{withdrawTransactionID}";
export const Get_Withdraw_Money_Request_Details_GET = BASE_URL + "withdrawRequest/{withdrawRequestID}";
export const Wallet_Balance_By_PlayerID_GET = BASE_URL + "wallet/getBalanceByPlayerId/{playerID}";
export const Wallet_Transaction_By_PlayerID_POST = BASE_URL + "walletAccountTransaction/byPlayerId/{playerID}";
export const Player_Update_KYC_Verification_Status_PUT = BASE_URL + "player/updateKycVerificationStatus/{playerID}";
export const Player_Ludo_Game_Session_List_POST = BASE_URL + "ludoGameSession/list/{playerID}";
export const Player_download_Excel_POST = BASE_URL + "player/downloadAllPlayers";
export const Player_GetUnVerifiedNumbers_GET = BASE_URL + "player/getUnVerifiedMobileNumber";
export const Player_GetSendBonusToPlayersSampleExcel_GET = BASE_URL + "ops/v1/uploadExcel/getSendBonusToPlayersSampleExcel";
export const Player_GetPlayerUuidByPlayerId = BASE_URL + "player/getPlayerUuidByPlayerId/{playerId}"

// Game Session History
export const Ludo_Game_Session_List_POST = BASE_URL + "ludoGameSession/list";
export const Ludo_Game_Session_Log_GET = BASE_URL + "ludoGameSession/{sessionId}";

// Wallet
export const Default_Wallet_List_GET = BASE_URL + "wallet/getDefaultWalletList/";
export const Wallet_Balance_By_WalletID_GET = BASE_URL + "wallet/getBalanceByWalletId/{walletID}";
export const Wallet_Transaction_By_WalletID_POST = BASE_URL + "walletAccountTransaction/byWalletId/{walletID}";

// Withdraw
export const Withdraw_Request_History_POST = BASE_URL + "withdrawRequest/history";
export const Withdraw_Request_List_POST = BASE_URL + "withdrawRequest/live";
export const Withdraw_Request_History_Details_GET = BASE_URL + "withdrawRequest/{withdrawRequestId}";
export const Withdraw_History_Excel_POST = BASE_URL + "withdrawRequest/downloadAllWithdrawHistory";

// Deposit
export const Deposit_Request_List_POST = BASE_URL + "paymentRequest/list";
export const Check_Deposit_Request_Details_PUT = BASE_URL + "paymentRequest/checkStatus/{paymentTransactionRefId}";
export const Update_Deposit_Request_Details_PUT = BASE_URL + "paymentRequest/updateStatus/{paymentTransactionRefId}";
export const Download_Deposit_Excel_POST = BASE_URL + "paymentRequest/downloadAllDeposit";

// Poker Stake
export const Poker_Stake_List_POST = BASE_URL + "poker/stake/list";
export const Add_Poker_Stake_POST = BASE_URL + "poker/stake/";
export const Edit_Poker_Stake_PUT = BASE_URL + "poker/turnTime/{recordId}";
export const Get_Details_Poker_Stake_GET = BASE_URL + "poker/stake/{recordId}";
export const Poker_Active_Stake_PUT = BASE_URL + "poker/stake/activate/{recordId}";
export const Poker_DeActive_Stake_PUT = BASE_URL + "poker/stake/deactivate/{recordId}";
export const Get_Poker_Stake_List_KeyValue_GET = BASE_URL + "poker/stake/listKeyValue";

// Poker TurnTime
export const Poker_TurnTime_List_POST = BASE_URL + "poker/turnTime/list";
export const Add_Poker_TurnTime_POST = BASE_URL + "poker/turnTime/";
export const Edit_Poker_TurnTime_PUT = BASE_URL + "poker/turnTime/{recordId}";
export const Get_Details_Poker_TurnTime_GET = BASE_URL + "poker/turnTime/{recordId}";
export const Poker_Active_TurnTime_PUT = BASE_URL + "poker/turnTime/activate/{recordId}";
export const Poker_DeActive_TurnTime_PUT = BASE_URL + "poker/turnTime/deactivate/{recordId}";
export const Get_Poker_Stake_TurnTime_KeyValue_GET = BASE_URL + "poker/turnTime/listKeyValue";

// Poker Game Template
export const Poker_PokerTemplate_List_POST = BASE_URL + "poker/pokerTemplate/list";
export const Add_Poker_PokerTemplate_POST = BASE_URL + "poker/pokerTemplate/";
export const Edit_Poker_PokerTemplate_PUT = BASE_URL + "poker/pokerTemplate/{recordId}";
export const Get_Details_Poker_PokerTemplate_GET = BASE_URL + "poker/pokerTemplate/{recordId}";
export const Poker_Active_PokerTemplate_PUT = BASE_URL + "poker/pokerTemplate/activate/{recordId}";
export const Poker_DeActive_PokerTemplate_PUT = BASE_URL + "poker/pokerTemplate/deactivate/{recordId}";
export const Poker_Delete_PokerTemplate_DELETE = BASE_URL + "poker/pokerTemplate/{recordId}";
export const TableType_List_GET = BASE_URL + "poker/pokerTemplate/getGameVariationList";
export const Start_Poker_TableType_List_GET = BASE_URL + "poker/pokerTemplate/startGame/{recordId}";
export const Stop_Poker_TableType_List_PUT = BASE_URL + "poker/pokerTemplate/stopTable/{recordId}";
export const Get_Running_Poker_TableType_List_GET = BASE_URL + "poker/pokerTemplate/getAllRunningTables";

// Admin Ad Banner Template
export const All_Ad_Banner_List_POST = BASE_URL + "banner/list";
export const Add_Banner_POST = BASE_URL + "banner/";
export const Get_Details_Banner_GET = BASE_URL + "banner/{recordId}";
export const Edit_Banner_PUT = BASE_URL + "banner/{recordId}";
export const Banner_Active_PUT = BASE_URL + "banner/activate/{recordId}";
export const Banner_DeActive_PUT = BASE_URL + "banner/deactivate/{recordId}";
export const Banner_Revive_PUT = BASE_URL + "banner/{recordId}/revive";
export const Delete_Banner_Record_DELETE = BASE_URL + "banner/{recordId}";
export const All_Deleted_Banner_List = BASE_URL + "banner/deletedList";
export const Banner_List_Keys = BASE_URL + "banner/listKeys";

// Poker Game Session
export const Poker_PokerSession_List_POST = BASE_URL + "poker/session/history-list";
export const Get_Details_Poker_PokerSession_GET = BASE_URL + "poker/session/{recordId}";

// Player
export const All_Player_List_POST = BASE_URL + "player/list";

// Avatar
export const All_Avatar_List_POST = BASE_URL + "avatar/list";

// Template
export const All_LudoGame_Template_List_POST = BASE_URL + "ludoGameTemplate/list";

// Template
export const All_Banner_List_POST = BASE_URL + "banner/list";

// Running Table List
export const All_Running_Table_List_POST = BASE_URL + "ludoGameTemplate/getAllRunningTables";

// Snake Ladder Asset List
export const All_Snake_Ladder_Asset_List_POST = BASE_URL + "snakeLadderAssets/list";

// Snake Ladder Game Template List
export const All_Snake_Ladder_Running_Tables_List_POST = BASE_URL + "snakeLadderGameTemplate/getAllRunningTables";
export const All_Snake_Ladder_Template_List_POST = BASE_URL + "snakeLadderGameTemplate/list";

//Ludo game setting
export const All_Game_Settings_List_GET = BASE_URL + "gameSettings/list/{game}";
export const Set_Game_Settings_PUT = BASE_URL + "gameSettings/{game}";

// Setting
export const Set_Upload_APK_PUT = BASE_URL + "settings/NewAPKUrl";
export const Upload_InMultipartFile_Post = BASE_URL + "uploadFile/inMultipartFile";

// Login Log
export const Get_Login_Logs_POST = BASE_URL + "player/logsList";

export const Add_Change_Agent_Player = BASE_URL + "player/changeAgent/{recordId}/{agentId}";

// Admin Agent Master Template
export const Add_Agent_AgentTemplate_POST = BASE_URL + "agent/";
export const Get_Details_Agent_AgentTemplate_GET = BASE_URL + "agent/{recordId}";
export const Update_Agent_AgentTemplate_PUT = BASE_URL + "agent/{recordId}";
export const Delete_Agent_AgentTemplate_Delete = BASE_URL + "agent/{recordId}";
export const Agent_Revive_AgentTemplate_PUT = BASE_URL + "agent/{recordId}/revive";
export const Agent_Activate_AgentTemplate_PUT = BASE_URL + "agent/activate/{recordId}";
export const Agent_Deactivate_AgentTemplate_PUT = BASE_URL + "agent/deactivate/{recordId}";
export const Agent_Change_Commission_AgentTemplate_POST = BASE_URL + "agent/changeCommission/{recordId}";
export const Agent_SendOut_POST = BASE_URL + "agent/sendOut/{recordId}";
export const Agent_ClaimBack_POST = BASE_URL + "agent/claimBack/{recordId}";
export const All_Deleted_AgentTemplate_List_POST = BASE_URL + "agent/deletedList";
export const All_Agent_AgentTemplate_List_POST = BASE_URL + "agent/list";
export const Agent_Reset_Password_PUT = BASE_URL + "agent/resetPassword/{recordId}";
export const Get_Admin_Assign_Agent_ListKeys_GET = BASE_URL + "agent/keyValueList";
export const Agent_Wallet_Commission_GET = BASE_URL + "agent/wallet/getTotalCommission";
export const Agent_Wallet_Commission_TransactionsList_GET = BASE_URL + "agent/wallet/allCommissionTransactionsList";

export const Wallet_Balance_By_AgentID_GET = BASE_URL + "wallet/getBalanceByAgentId/{recordId}";
export const Wallet_Transaction_By_AgentID_POST = BASE_URL + "walletAccountTransaction/byAgentId/{recordId}";


// Agent Player Template
export const Add_Agent_PlayerTemplate_POST = BASE_URL + "agent/player/";
export const Get_Details_Agent_PlayerTemplate_GET = BASE_URL + "agent/player/{recordId}";
export const Update_Agent_PlayerTemplate_PUT = BASE_URL + "agent/player/{recordId}";
export const Delete_Agent_PlayerTemplate_Delete = BASE_URL + "agent/player/{recordId}";
export const Agent_Revive_PlayerTemplate_PUT = BASE_URL + "agent/player/{recordId}/revive";
export const Agent_Activate_PlayerTemplate_PUT = BASE_URL + "agent/player/activate/{recordId}";
export const Agent_Deactivate_PlayerTemplate_PUT = BASE_URL + "agent/player/deactivate/{recordId}";
export const All_Deleted_Agent_PlayerTemplate_List_POST = BASE_URL + "agent/player/deletedList";
export const All_Agent_PlayerTemplate_List_POST = BASE_URL + "agent/player/list";
export const Get_Agent_Total_Player_Count_GET = BASE_URL + "agent/player/getPlayerCount";

// Agent Poker Game Session Document Controller

export const Agent_Poker_Session_Details_GET = BASE_URL + "agent/poker/session/{sessionId}"
export const Agent_Poker_Session_History_POST = BASE_URL + "agent/poker/session/history-list/{playerId}"

//Agent Player wallet details
export const Agent_Player_ClaimBack_PUT = BASE_URL + "agent/player/wallet/claimBack/{recordId}";
export const Agent_Player_SendOut_PUT = BASE_URL + "agent/player/wallet/sendOut/{recordId}";

export const Wallet_Balance_By_Agent_PlayerID_GET = BASE_URL + "agent/player/wallet/getBalance/{recordId}";
export const Wallet_Transaction_By_Agent_PlayerID_POST = BASE_URL + "agent/player/wallet/walletAccountTransaction/{recordId}";

export const Agent_Wallet_Balance_GET = BASE_URL + "agent/wallet/getBalance";
export const Agent_Wallet_Transaction_POST = BASE_URL + "agent/wallet/allTransactionsList";

//Agent Player Withdraw details
export const Agent_Player_Withdraw_Money_Request_List_POST = BASE_URL + "agent/player/withdrawRequest/withdrawMoneyRequestList/{recordId}";
export const Approve_Agent_Player_Withdraw_Request_PUT = BASE_URL + "agent/player/withdrawRequest/approveRequest/{recordId}";
export const Reject_Agent_Player_Withdraw_Request_PUT = BASE_URL + "agent/player/withdrawRequest/rejectRequest/{recordId}";

//Agent Player Ludo Game Session Details
export const Agent_Player_Ludo_Game_Session_List_POST = BASE_URL + "agent/player/ludoGameSession/ludoGameSessionList/{recordId}";

//Agent Payment request details
export const Agent_Player_History_Payment_Request_List_POST = BASE_URL + "agent/player/paymentRequest/historyPaymentRequest/{recordId}";
export const Agent_Player_History_Payment_Check_Status_PUT = BASE_URL + "agent/player/paymentRequest/checkStatus/{recordId}";
export const Agent_Player_History_Payment_Update_Status_PUT = BASE_URL + "agent/player/paymentRequest/updateStatus/{recordId}";

//Admin Voucher Details
export const Add_Admin_VoucherTemplate_POST = BASE_URL + "voucher/";
export const All_Admin_VoucherTemplate_List_POST = BASE_URL + "voucher/list";

//Agent Profile Details
export const Agent_Profile_Change_Password = BASE_URL + "agent/profile/changePassword";
export const Get_Agent_Balance = BASE_URL + "agent/wallet/getBalance";
//Agent Voucher Details
export const Add_Agent_VoucherTemplate_POST = BASE_URL + "agent/voucher/";
export const All_Agent_VoucherTemplate_List_POST = BASE_URL + "agent/voucher/list";

//Super Admin Setting
const Super_Admin_Setting_URL = BASE_URL + "superAdminSettings/";
export const Get_Details_Setting_Key_GET = Super_Admin_Setting_URL + "{recordId}";
export const Update_Super_Admin_Settings_PUT = Super_Admin_Setting_URL + "{recordId}";
export const Get_All_Super_Admin_Settings_List_GET = Super_Admin_Setting_URL + "list";
export const Get_Super_Admin_ListKeys_GET = Super_Admin_Setting_URL + "listKeys";
export const Get_Super_Admin_UrlList_GET = Super_Admin_Setting_URL + "urlList";
export const Super_Admin_UpdateUrl_PUT = Super_Admin_Setting_URL + "updateUrl/{settingKey}";

// admin report details
export const All_Number_Of_Depositing_Player_Count_POST = BASE_URL + "report/getDepositCount/{reportType}";
export const All_Number_Of_Game_Played_Count_POST = BASE_URL + "report/getGamePlayedCount/{reportType}";
export const All_Registered_Player_Count_POST = BASE_URL + "report/getPlayerCount/{reportType}";
export const Filter_Player_By_Deposit_Count_POST = BASE_URL + "report/filterPlayerByDepositCount/{count}";
export const Filter_Player_By_Game_Count_POST = BASE_URL + "report/filterPlayerByGameCount/{count}";
export const Spin_Player_By_Game_Spin_Count_POST = BASE_URL + "report/spinCount";

// Coupon master
export const Add_New_Coupon_Code_POST = BASE_URL + "couponCode/";
export const Coupon_Code_GET = BASE_URL + "couponCode/{recordId}";
export const Update_Existing_Coupon_Code_PUT = BASE_URL + "couponCode/{recordId}";
export const Delete_Existing_Coupon_Code_DELETE = BASE_URL + "couponCode/{recordId}";
export const Coupon_Code_Revive__PUT = BASE_URL + "couponCode/{recordId}/revive";
export const Coupon_Code_Activate_PUT = BASE_URL + "couponCode/activate/{recordId}";
export const Coupon_Code_Deactivate_PUT = BASE_URL + "couponCode/deactivate/{recordId}";
export const All_Deleted_Coupon_Code_List_POST = BASE_URL + "couponCode/deletedList";
export const Coupon_Code_KeyValue_List_GET = BASE_URL + "couponCode/keyValueList";
export const Coupon_Code_List_POST = BASE_URL + "couponCode/list";

// Store Product Controller 
export const Add_New_Admin_Store_Product_POST = BASE_URL + "storeProduct/";
export const Admin_Store_Product_GET = BASE_URL + "storeProduct/{recordId}";
export const Update_Admin_Store_Product_PUT = BASE_URL + "storeProduct/{recordId}";
export const Delete_Admin_Store_Product_DELETE = BASE_URL + "storeProduct/{recordId}";
export const Admin_Store_Product_Revive__PUT = BASE_URL + "storeProduct/{recordId}/revive";
export const Admin_Store_Product_Activate_PUT = BASE_URL + "storeProduct/activate/{recordId}";
export const Admin_Store_Product_Deactivate_PUT = BASE_URL + "storeProduct/deactivate/{recordId}";
export const All_Deleted_Admin_Store_Product_List_POST = BASE_URL + "storeProduct/deletedList";
export const Admin_Store_Product_List_POST = BASE_URL + "storeProduct/list";

//System Message Controller 
export const Add_New_Admin_System_Message_POST = BASE_URL + "systemMessage/";
export const Admin_System_Message_GET = BASE_URL + "systemMessage/{recordId}";
export const Update_Admin_System_Message_PUT = BASE_URL + "systemMessage/{recordId}";
export const Delete_Admin_System_Message_DELETE = BASE_URL + "systemMessage/{recordId}";
export const Admin_System_Message_Revive__PUT = BASE_URL + "systemMessage/{recordId}/revive";
export const Admin_System_Message_Activate_PUT = BASE_URL + "systemMessage/activate/{recordId}";
export const Admin_System_Message_Deactivate_PUT = BASE_URL + "systemMessage/deactivate/{recordId}";
export const All_Deleted_Admin_System_Message_List_POST = BASE_URL + "systemMessage/deletedList";
export const Admin_System_Message_List_POST = BASE_URL + "systemMessage/list";

// Admin lucky wheel controller
export const Add_New_Admin_Lucky_Wheel_POST = BASE_URL + "luckyWheel/";
export const Admin_Lucky_Wheel_GET = BASE_URL + "luckyWheel/{recordId}";
export const Update_Lucky_Wheel_Product_PUT = BASE_URL + "luckyWheel/{recordId}";
export const Delete_Lucky_Wheel_Product_DELETE = BASE_URL + "luckyWheel/{recordId}";
export const Admin_Lucky_Wheel_Revive__PUT = BASE_URL + "luckyWheel/{recordId}/revive";
export const Admin_Lucky_Wheel_Activate_PUT = BASE_URL + "luckyWheel/activate/{recordId}";
export const Admin_Lucky_Wheel_Deactivate_PUT = BASE_URL + "luckyWheel/deactivate/{recordId}";
export const All_Deleted_Admin_Lucky_Wheel_List_POST = BASE_URL + "luckyWheel/deletedList";
export const Admin_Lucky_Wheel_List_POST = BASE_URL + "luckyWheel/list";
export const ItemTypeKeyList_GET = BASE_URL + "luckyWheel/itemTypeKeyList";
export const WheelTypeKeyList_GET = BASE_URL + "luckyWheel/wheelTypeKeysList";

//Banned Status 
export const Admin_Banned_Status_POST = BASE_URL + "bannedStates/";
export const Admin_Banned_Status_List_POST = BASE_URL + "bannedStates/list";
export const Admin_Banned_Status_Delete_POST = BASE_URL + "bannedStates/{uuid}";

//Question Answer master
export const Add_New_Admin_Question_Answer_POST = BASE_URL + "questionAnswer/";
export const GET_Admin_Question_Answer_GET = BASE_URL + "questionAnswer/{uuid}";
export const PUT_Admin_Question_Answer_PUT = BASE_URL + "questionAnswer/{uuid}";
export const Delete_Admin_Question_Answer_DELETE = BASE_URL + "questionAnswer/{uuid}";
export const Update_Admin_Question_Answer_Revive_PUT= BASE_URL + "questionAnswer/{uuid}/revive";
export const Activate_Admin_Question_Answer_PUT= BASE_URL + "questionAnswer/activate/{uuid}";
export const Deactivate_Admin_Question_Answer_PUT= BASE_URL + "questionAnswer/deactivate/{uuid}";
export const Deleted_List_Admin_Question_Answer_POST= BASE_URL + "questionAnswer/deletedList";
export const List_Admin_Question_Answer_List_POST= BASE_URL + "questionAnswer/list";
export const Get_Question_Answer_Category_List_POST= BASE_URL + "questionAnswer/getQuestionCategories";
export const Get_Question_Level_List_POST= BASE_URL + "questionAnswer/getQuestionLevel";

// player level master
export const Add_New_Player_Level_POST = BASE_URL + "player-level/";
export const GET_Player_Level_GET = Add_New_Player_Level_POST + "{id}";
export const UPDATE_Player_Level_PUT = Add_New_Player_Level_POST + "{id}";
export const Get_Player_Level_List_Post = Add_New_Player_Level_POST + "list";