import React, { useCallback, useMemo, useState } from 'react'
import PaginationDataTable from '../../Common/PaginationDataTable';
import CustomButton from '../../Common/CustomButton';
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import Edit
import PageHeading from '../../Common/PageHeading';
import { Get_Player_Level_List_Post } from '../../../api/ApiConst';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { addPlayerLevel, getPlayerLevelDetails, updatePlayerLevel, uploadPlayerLevelIcon } from '../../../Services/MasterServices/PlayerLevelServices';
import ConfirmationDialoge from '../../Common/ConfirmationDialoge';
import Cookies from 'universal-cookie';
import $ from 'jquery';


function PlayerLevelMaster() {
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState({ addPlayer: false });
    const [record, setRecord] = useState({
        title: "",
        description: "",
        pointFrom: "",
        pointTo: "",
    });
    const [selectedImage, setSelectedImage] = useState("");
    const [bodyFormFileData, setbodyFormFileData] = useState("");
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [id, setId] = useState();
    const [drawCount, setDrawCount] = useState(0);
    const handleRefresh = useCallback(() => {
        setDrawCount(drawCount + 1);
    }, [drawCount])

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleEditRow = (id) => {
        
        setId(id);
        setShow({ ...show, addPlayer: true });
        setEdit(true);
        getPlayerLevelDetails(id).then((res) => {
            
            if (res.errorCode === 0) {
                let details = res.responsePacket;
                $("#assetsImageInput").val(details.levelIconImageUrl);
                setSelectedImage(details.levelIconImageUrl);
                setRecord({
                    title: details.levelTitle || "",
                    description: details.levelDescription || "",
                    pointFrom: details.pointFrom ?? "",
                    pointTo: details.pointTo ?? "",
                })
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };
    const columns = useMemo(
        () =>
            [
                {
                    id: "image",
                    name: "Badges",
                    selector: (row, index) => {
                        return <span className="profile-img">{row.levelIconImageUrl ? <img src={row.levelIconImageUrl} alt={"User"} /> : <img src="/assets/images/user.png" alt={"User"} />}</span>;
                    },
                    // width: "80px",
                    sortable: false,
                },
                {
                    name: "Title",
                    selector: (row) => row.levelTitle,
                    sortable: true,
                    // width: "120px",
                },
                {
                    name: "Description",
                    selector: (row) => row.levelDescription,
                    sortable: true,
                    // width: "120px",
                },
                {
                    name: "Point From",
                    selector: (row) => row.pointFrom,
                    sortable: true,
                    // width: "120px",
                },
                {
                    name: "Point To",
                    selector: (row) => row.pointTo,
                    sortable: true,
                    // width: "120px",
                },
                {
                    name: "Actions",
                    selector: (row) => (
                        <button className="btn btn-primary" type="button" onClick={() => handleEditRow(row.recordId)}>
                            Edit
                        </button>
                    ),
                    width: "100px"
                },
            ],
        [handleRefresh]
    );

    const handleAddPlayer = () => {
        setEdit(false)
        setShow({ ...show, addPlayer: true })

    };
    const handlePlayerLevelModal = () => {
        setShow({ ...show, addPlayer: false });
        handleClearForm();
    };
    const handleImageUpload = (e) => {
        var input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                setSelectedImage(ev.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
        let image = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", image);
        setbodyFormFileData(bodyFormData);
    };
    const handleChange = (e) => {
        setRecord((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        })
    };
    const validateAddForm = () => {
        if (!selectedImage) {
            toast.error("Please select an image", { toastId: "toast3" });
            return false;
        }
        if (!record.title) {
            toast.error("Title must not be empty.", { toastId: "toast3" });
            return false;
        };
        if (!record.description) {
            toast.error("Description must not be empty.", { toastId: "toast3" });
            return false;
        };
        if (record.pointFrom === undefined || record.pointFrom === null || record.pointFrom === "") {
            toast.error("Point from must not be empty.", { toastId: "toast3" });
            return false;
        }; 
        if(record.pointFrom < 0) {
            toast.error("Point from must not be negative", {toastId: "toast3"});
        };
        if (!record.pointTo) {
            toast.error("point to must not be empty.", { toastId: "toast3" });
        }
        if (record.pointFrom < 0) {
            toast.error("Point from must not be negative.", { toastId: "toast3" });
            return false;
        };
        
        if (record.pointTo == 0) {
            toast.error("Point to  is not be 0", { toastId: "toast3" });
            return false;
        };
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let inputTag = $("#formrow-inputZip").val();
        
        if (inputTag != "") {
            
            uploadPlayerLevelIcon(bodyFormFileData).then((res) => {
                
                if (res.errorCode === 0) {
                    $("#assetsImageInput").val(res.responsePacket);
                    submitForm();
                }
            });
        } else {
            submitForm();

        }
    };
    const submitForm = () => {
        if (!validateAddForm()) {
            return;
        };
        let requestObj = {
            levelIconImageUrl: $("#assetsImageInput").val(),
            levelTitle: record.title,
            levelDescription: record.description,
            pointFrom: record.pointFrom,
            pointTo: record.pointTo,
        };
        if (id) {

            updatePlayerLevel(id, requestObj).then((res) => {
                
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message)
                    handleRefresh();
                    handleClearForm();
                }else {
                    toast.error(res?.message, { toastId: "toast3" });
                    return;
                }
            })
        } else {
            addPlayerLevel(requestObj).then((res) => {
                if (res.errorCode === 0) {  
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleClearForm();
                }else {
                    toast.error(res?.message, { toastId: "toast3" });
                    return;
                }
            })
        }
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            toastId: "successToast",
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleClearForm = () => {
        setSelectedImage("");
        setRecord({
            title: "",
            description: "",
            pointFrom: "",
            pointTo: "",
        });
        $("#assetsImageInput").val("");
        setShow({ ...show, addPlayer: false });
    };

    const playerLevelModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addPlayer} onHide={() => handlePlayerLevelModal()}
                size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>{edit ? "Edit Player" : "Add Player"}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => handlePlayerLevelModal()}
                        ></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="formrow-inputZip" className="form-label">
                                                Upload Image
                                            </label>
                                            <input type="hidden" id="imageId" />
                                            <input type="hidden" id="assetsImageInput" />
                                            <img
                                                src={selectedImage !== "" ? selectedImage : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                                                id="assetImageImg"
                                                style={{
                                                    height: "130px",
                                                    width: "100%",
                                                    objectFit: "contain",
                                                    border: "1px solid #7c7979",
                                                    padding: "10px",
                                                }}
                                            />
                                            <input type="file" className="form-control" accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                                            <label className="form-label mt-4">
                                                Level Title
                                            </label>
                                            <input type="text" className="form-control" value={record.title} name="title" onChange={handleChange} />
                                            <label className="form-label mt-4">
                                                Level Description
                                            </label>
                                            <input type="text" className="form-control" value={record.description} name="description" onChange={handleChange} />
                                            <label className="form-label mt-4">
                                                Point From
                                            </label>
                                            <input type="number" className="form-control" value={record.pointFrom} name="pointFrom" onChange={handleChange} min={0} />
                                            <label className="form-label mt-4">
                                                Point To
                                            </label>
                                            <input type="number" className="form-control" value={record.pointTo} name="pointTo" onChange={handleChange} min={-1} />
                                        </div>
                                        {/* {edit ? <div><img src={selectedImage.ImageUrl} style={{height: '60px',paddingTop: '7px'}}/></div> : ""} */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Button type='submit' className="btn btn-primary float-end mt-2"
                                    onClick={handleSubmit}
                                >Submit</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    const cookies = new Cookies();
    const loginData = cookies.get("loginData") && cookies.get("loginData") !== undefined ? cookies.get("loginData") : null;
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Player Level" />
                    <div className="card">

                        <div className="card-body">
                            {loginData?.userType === "ROLE_SUPER_ADMIN" && <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                                <div style={{ width: "200px" }}>
                                    <CustomButton
                                        handleClick={handleAddPlayer}
                                        title="Add Level"
                                        iconClass="bx bx-plus"
                                        buttonClass="createButton"
                                    />
                                </div>
                                <div style={{ width: "200px" }}>

                                </div>
                            </div>}
                            <div className="mt-5">
                                <PaginationDataTable
                                    paginationUrl={Get_Player_Level_List_Post}
                                    serverSidePagination={true}
                                    search={false}
                                    columns={columns}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show.addPlayer && playerLevelModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}

export default PlayerLevelMaster