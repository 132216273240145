import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Tooltip } from "react-tooltip";

import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
    List_Admin_Question_Answer_List_POST,
    Deleted_List_Admin_Question_Answer_POST, Get_Question_Answer_Category_List_POST,
    Get_Question_Level_List_POST
} from "../api/ApiConst";
import PaginationDataTable from "./Common/PaginationDataTable";
import PageHeading from "./Common/PageHeading";
import CustomButton from "./Common/CustomButton";
import CheckboxButton from "./Common/CheckboxButton";
import {
    activateQuestion,
    addQuestion,
    deactivateQuestion,
    deleteQuestion,
    getCategories,
    getQuestionDetails,
    reviveQuestion,
    updateQuestionDetails,
} from "../Services/MasterServices/QuestionAnswerService";
import { SYSTEM_ERROR } from "../Services/Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersData = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};

const QuestionAnswerMaster = () => {
    const [show, setShow] = useState({
        addQuestion: false,
    });
    const [drawCount, setDrawCount] = useState(0);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });
    const [categories, setCategories] = useState([]);
    const [levelOptions, setLevelOptions] = useState([])

    const [viewDeletedList, setViewDeletedList] = useState(false);
    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);
    const handleRefresh = () => setDrawCount((prev) => prev + 1);


    const [record, setRecord] = useState({
        questionCategory: "",
        levelId: '1',
        answerHint: "",
        correctAnswer: "",
        optionA: "",
        optionB: "",
        question: "",
        // steps: 0
    });

    const CorrectAnswerOptions = [
        { value: record.optionA, label: "Option A" },
        { value: record.optionB, label: "Option B" },
    ];

    const handleAddQuestionModal = () => {
        setShow({ ...show, addQuestion: false });
        handleClearForm();
    };

    const handleNewQuestion = () => {
        setEdit(false);
        setShow({ ...show, addQuestion: true });
        setId(null);
    };


    const validateAddForm = () => {
        if (!record.questionCategory) {
            toast.error("Category field cannot be empty.");
            return false;
        }
        if (!record.levelId) {
            toast.error("Level field cannot be empty.");
            return false;
        }
        if (!record.question || !record.question.trim()) {
            toast.error("Question field cannot be empty.");
            return false;
        }
        if (!record.optionA || !record.optionA.trim()) {
            toast.error("Option A cannot be empty.");
            return false;
        }
        if (!record.optionB || !record.optionB.trim()) {
            toast.error("Option B cannot be empty.");
            return false;
        }
        if (!record.correctAnswer || !record.correctAnswer.trim()) {
            toast.error("You must select a correct answer.");
            return false;
        }
        if (!record.answerHint) {
            toast.error("Answer hint cannot be empty.");
            return false;
        }
        // if (!record.steps) {
        //     toast.error("Steps cannot be empty.");
        //     return false;
        // }
        return true;
    };

    const handleInputChange = (e) => {
        const { value, id } = e.target;
        setRecord({ ...record, [id]: value });
    }

    const handleSelectChange = (selectedOption) => {
        setRecord((prev) => ({
            ...prev,
            correctAnswer: selectedOption.value,
        }));
    };

    const handleCategorySelectChange = (selectedOption) => {
        setRecord({
            ...record,
            questionCategory: selectedOption
        });
    };
    
    const handleLevelSelectChange = (selectedOption) => {
        setRecord({
            ...record,
            levelId: selectedOption
        });
    };

    const handleDeleteQuestion = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteQuestion(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };

    const getQuestionCategories = () => {
        getCategories().then((res) => {
            let data = res.responsePacket;
            let questionCategories = data.map((item) => ({
                key: item,   // Use the current array element as the key
                value: item,
                label: item  // Use the current array element as the value
            }));
            setCategories(questionCategories);
        });
    };
    
    const getQuestionLevelList = () => {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .get(Get_Question_Level_List_POST,{ headers: headersData })
                    .then((res) => {
                        resolve(res);
                        const data = res.data.responsePacket;
                        
                        const questionLevels = data.map((item) => ({
                            key: item.recordId,
                            value: item.recordId,
                            label: item.levelTitle 
                        }));
                        
                        setLevelOptions(questionLevels)
                    })
                    .catch((err) => {
                        reject("Error in getPlayerDetails axios!");
                    });
            } catch (error) {
                reject(SYSTEM_ERROR);
            }
        });
    };

    useEffect(()=>{
        getQuestionCategories();
        getQuestionLevelList();
    },[])

    const handleReviveData = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        reviveQuestion(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    }
    const handleQuestionDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateQuestion(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };
    const handleQuestionActivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateQuestion(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleEditQuestion = (id) => {
        setId(id);
        setShow({ ...show, addQuestion: true });
        setEdit(true);

        getQuestionDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let questionDetails = res.responsePacket;
                let _category = categories.find((item) => item.value === questionDetails?.questionCategory);
                let _level = levelOptions.find((item) => item.value === questionDetails?.levelId);
                let dict = {
                    questionCategory: _category,
                    question: questionDetails?.question,
                    optionA: questionDetails?.optionA,
                    optionB: questionDetails?.optionB,
                    correctAnswer: questionDetails?.correctAnswer,
                    answerHint: questionDetails?.answerHint,
                    levelId: _level
                    // steps: questionDetails?.steps,
                };
                setRecord(dict);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const columns = useMemo(
        () => [{
            id: "status",
            name: "",
            selector: (row) => {
                if (row.active) {
                    return <div className="led-green" productTitle="Active"></div>;
                } else {
                    return <div className="led-red" productTitle="Not Active"></div>;
                }
            },
            width: "60px",
            sortable: false,
        },
        {
            name: "Question",
            selector: (row) => (row.question && row.question !== "" ? row.question : row.question),
            sortable: true,
            wrap: true,
            cell: (row) => {
                const question = row.question || "";
                const truncatedQuestion = question;
                /* question.split(" ").length > 8
                    ? question.split(" ").slice(0, 8).join(" ") + "..."
                    : question; */
                return (
                    <div>
                        <span data-tooltip-id={`tooltip-${row.id}`} data-tooltip-content={question}>
                            {truncatedQuestion}
                        </span>
                        <Tooltip id={`tooltip-${row.id}`} effect="solid" />
                    </div>
                );
            },
        },
        {
            name: "Option A",
            selector: (row) => row.optionA || "",
            sortable: true,
            wrap: true,
            width: "200px",
            cell: (row) => {
                const optionA = row.optionA || "";
                const isCorrect = row.correctAnswer === optionA; // Check if Option A matches correctAnswer
                const truncatedOptionA =
                    optionA.split(" ").length > 4
                        ? optionA.split(" ").slice(0, 4).join(" ") + "..."
                        : optionA;

                return (
                    <div>
                        <span
                            data-tooltip-id={`tooltip-${row.id}`}
                            data-tooltip-content={optionA}
                        >
                            {isCorrect && "✔️ "} {/* Add tick if Option A is correct */}
                            {truncatedOptionA}
                        </span>
                        <Tooltip id={`tooltip-${row.id}`} effect="solid" />
                    </div>
                );
            },
        },
        {
            name: "Option B",
            selector: (row) => row.optionB || "",
            sortable: true,
            wrap: true,
            width: "200px",
            cell: (row) => {
                const optionB = row.optionB || "";
                const isCorrect = row.correctAnswer === optionB; // Check if Option B matches correctAnswer
                const truncatedOptionB =
                    optionB.split(" ").length > 4
                        ? optionB.split(" ").slice(0, 4).join(" ") + "..."
                        : optionB;

                return (
                    <div>
                        <span
                            data-tooltip-id={`tooltip-${row.id}`}
                            data-tooltip-content={optionB}
                        >
                            {isCorrect && "✔️ "} {/* Add tick if Option B is correct */}
                            {truncatedOptionB}
                        </span>
                        <Tooltip id={`tooltip-${row.id}`} effect="solid" />
                    </div>
                );
            },
        },
        {
            name: "Category",
            selector: (row) => (row.questionCategory && row.questionCategory !== "" ? row.questionCategory : row.questionCategory),
            sortable: true,
            width: "120px",
            wrap: true,
            right: true,
        },
        {
            name: "Level",
            selector: (row) => (row.levelId && row.levelId),
            sortable: true,
            wrap: true,
            right: true,
        },
        {
            name: "Actions",
            width: "100px",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <KeyboardArrowDownOutlinedIcon />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        {!viewDeletedList && (
                            <li onClick={() => handleEditQuestion(row.recordId)}>
                                <button className="dropdown-item" type="button">
                                    Edit
                                </button>
                            </li>
                        )}
                        {!viewDeletedList && (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are You Sure? You want to Delete this record",
                                        onConfirm: () => handleDeleteQuestion(row.recordId),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Delete
                                </button>
                            </li>
                        )}
                        {viewDeletedList ? (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are You Sure? You want to Revive this record",
                                        onConfirm: () => handleReviveData(row.recordId),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Revive
                                </button>
                            </li>
                        ) : (
                            row.active ? (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Deactivate this Question",
                                            onConfirm: () => handleQuestionDeactivate(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Deactivate
                                    </button>
                                </li>
                            ) : (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Activate this Question",
                                            onConfirm: () => handleQuestionActivate(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Activate
                                    </button>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            ),
            sortable: true,
            right: true,
        }
        ],
        [drawCount, viewDeletedList, categories, levelOptions]
    );

    const handleAdd = (e) => {
        e.preventDefault();
        submitForm();
    };

    const submitForm = () => {
        const isFormValid = validateAddForm();
        if (!isFormValid) {
            return false;
        }

        let request = {
            questionCategory: record?.questionCategory?.value,
            levelId: record?.levelId?.value,
            question: record?.question.trim(),
            optionA: record?.optionA.trim(),
            optionB: record?.optionB.trim(),
            correctAnswer: record?.correctAnswer.trim(),
            answerHint: record?.answerHint.trim(),
            // steps: parseInt(record?.steps),
        };

        if (id) {
            updateQuestionDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddQuestionModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailedToast(res?.message);
                }
            })
        } else {
            addQuestion(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddQuestionModal();

                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailedToast(res?.message);
                }
            });
        }
    };
    
    // const handleEditStore = (id) => {
    //     setId(id);
    //     setShow({ ...show, addStore: true });
    //     setEdit(true);

    //     getStoreDetails(id).then((res) => {
    //         if (res.errorCode === 0) {

    //             let questionDetails = res.responsePacket;
    //             const _category = categories.find((item) => item.value === questionDetails?.questionCategory);
    //             const _level = levelOptions.find(item => item.value === questionDetails?.levelId);
                
    //             let dict = {
    //                 questionCategory: _category,
    //                 question: questionDetails?.question,
    //                 optionA: questionDetails?.optionA,
    //                 optionB: questionDetails?.optionB,
    //                 correctAnswer: questionDetails?.correctAnswer,
    //                 answerHint: questionDetails?.answerHint,
    //                 levelId: _level
    //                 // steps: questionDetails?.steps,
    //             };
    //             setRecord(dict);
    //         } else if (res.message === "Unauthorised Access") {
    //             handleForceLogout(res.message);
    //         }
    //     });
    // };

    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleFailedToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleClearForm = () => {
        setRecord({
            productTitle: "",
            appleProductId: "",
            chips: 0,
            diamond: 0,
            goldCard: 0,
            googleProductId: "",
            productDescription: "",
            productPrice: 0,
            productTitle: "",
            purpleCard: 0
        });
    };

    const addQuestionModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addQuestion} onHide={() => handleAddQuestionModal()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>{edit ? "Edit Question" : "Add Question"}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddQuestionModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row mb-3">
                                <div className="col-lg-6 mb-3">
                                    <label>Question Category</label>
                                    <Select
                                        id="categories"
                                        options={categories || []}
                                        value={record.questionCategory}
                                        onChange={handleCategorySelectChange}
                                    />
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label>Level</label>
                                    <Select
                                        id="levelId"
                                        options={levelOptions|| []}
                                        value={record.levelId}
                                        onChange={handleLevelSelectChange}
                                    />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Question
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="question"
                                        value={record.question}
                                        name="question"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Option A
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="optionA"
                                        value={record.optionA}
                                        name="optionA"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Option B
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="optionB"
                                        value={record.optionB}
                                        name="optionB"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label>Correct Answer</label>
                                    <Select
                                        options={CorrectAnswerOptions}
                                        id='correctAnswer'
                                        value={CorrectAnswerOptions.find(
                                            (option) => option.value === record.correctAnswer
                                        )}
                                        onChange={handleSelectChange}

                                    />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Answer Hint
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="answerHint"
                                        name="answerHint"
                                        value={record.answerHint}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                {/* <div className="col-lg-12 mb-3">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Steps
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        className="form-control"
                                        id="steps"
                                        name="steps"
                                        value={record.steps}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div> */}

                            </div>


                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAdd}>
                                    {edit ? "Update Question" : "Add Question"}
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddQuestionModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal >
        );
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Question-List" />
                    <div className="card">

                        <div className="card-body">
                            <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                                <div style={{ width: "200px" }}>
                                    <CustomButton handleClick={handleNewQuestion} title="Add Question" iconClass="bx bx-plus" buttonClass="createButton" />
                                </div>
                                <div style={{ width: "200px" }}>

                                </div>
                            </div>
                            <div className="mt-5">
                                <PaginationDataTable
                                    paginationUrl={viewDeletedList ? Deleted_List_Admin_Question_Answer_POST : List_Admin_Question_Answer_List_POST}
                                    serverSidePagination={true}
                                    search={false}
                                    columns={columns}
                                    additionalClassName={"agentSearchInput"}
                                />

                            </div>
                            <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                        </div>
                    </div>
                </div>
            </div>
            {show.addQuestion && addQuestionModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default QuestionAnswerMaster;
