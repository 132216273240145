import React, {/* useMemo,  */ useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { callGetApi, callPutApi } from "../../../api/ApiCaller.js";
import {
    All_Game_Settings_List_GET,
    CLIENT_ID,
    LUDO_SERVER_URL,
    Set_Game_Settings_PUT,
} from "../../../api/ApiConst.js";
import PageHeading from "../../Common/PageHeading.jsx";

const LudoGame = () => {
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 d-flex align-items-center">
                            <PageHeading
                                heading={`Ludo`}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <iframe
                                src={LUDO_SERVER_URL.replace(
                                    "{clientId}",
                                    CLIENT_ID
                                )}
                                title="Ludo Setup"
                                style={{
                                    width: "100%",
                                    height: "72vh",
                                }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default LudoGame;
