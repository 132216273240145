import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import PaginationDataTable from "../Common/PaginationDataTable";
import { Agent_Player_Ludo_Game_Session_List_POST, Ludo_Game_Session_List_POST, Ludo_Game_Session_Log_GET, Player_Ludo_Game_Session_List_POST } from "../../api/ApiConst";
import { useMemo } from "react";
import dateUtilities from "../../utilities/DateUtilities";
import { useCallback } from "react";
import { useEffect } from "react";
import { callGetApi } from "../../api/ApiCaller";
import { Link } from "react-router-dom";

const GameSession = (props) => {
    const [showGameLogModal, setShowGameLogModal] = useState(false);
    const [sessionDetails, setSessionDetails] = useState(false);
    useEffect(() => {
        if (!showGameLogModal) {
            setSessionDetails(null);
        }
    }, [showGameLogModal]);

    const columns = useMemo(
        () => [{
            id: "sessionId",
            name: "Session ID",
            selector: (row) => actionButtons(row),
            sortable: false,
            width: "200px"
        }, {
            id: "totalPlayerCount",
            name: "Players",
            selector: (row) => row.totalPlayerCount,
            sortable: false,
            center: true,
            width: "100px"
        },
         {
            id: "totalBetAmount",
            name: "Total BetAmount ",
            selector: (row) => row.totalBetAmount?.toFixed(2),
            sortable: false,
            right: true,
            width: "150px"
        }, 
         {
            id: "feeAmount",
            name: "Fee Amount ",
            selector: (row) => row.feeAmount?.toFixed(2),
            sortable: false,
            right: true,
            width: "120px"
        }, 
         {
            id: "betAmount",
            name: "Bet Amount ",
            selector: (row) => row.betAmount?.toFixed(2),
            sortable: false,
            right: true,
            width: "120px"
        }, 
        {
            id: "totalWinAmount",
            name: "Win ",
            selector: (row) => row.totalWinAmount?.toFixed(2),
            sortable: false,
            right: true,
            width: "80px"
        }, {
            id: "startDateTime",
            name: "Start Date",
            selector: (row) => dateUtilities.formattedDate(row?.startDateTimeStamp, "dd-MM-yyyy p"),
            sortable: false,
            width: "160px"
        }, {
            id: "endDateTime",
            name: "End Date",
            selector: (row) => dateUtilities.formattedDate(row?.endDateTimeStamp, "dd-MM-yyyy p"),
            sortable: false,
            // width: "160px"
        }, {
            id: "gameSessionWinnerPlayerList",
            name: "Winner (s)",
            selector: (row) => getWinnersList(row),
            sortable: false,
            width: "130px"
        }],
        []
    );

    //Action Buttons
    const actionButtons = useCallback((row) => {
        return (
            <div
                role="button"
                className="text-primary "
                onClick={() => handleViewDetails(row)}>
                {row.sessionId}
            </div>
        );
    }, []);

    // Get Winners list
    const getWinnersList = (row) => {
        return (

            <div className="d-flex" key={row.recordId}>
                {
                    row?.gameSessionWinnerPlayerList?.map((winner, i) =>
                        getPlayerSessionHistoryLink(winner)
                    )
                }
            </div>

        )
    }
    const getPlayerSessionHistoryLink = (player) => {
        return (player.playerVisibleName !== null && player.playerVisibleName !== undefined) ?
            <Link to={"/playerDetail?rId=" + player.playerId} target="_blank">{` ${player.playerVisibleName}`}</Link> : "";

    }

    const handleViewDetails = (row) => {
        let url = Ludo_Game_Session_Log_GET.replaceAll("{sessionId}", row.sessionId);
        callGetApi(
            url,
            {},
            (response) => {
                setSessionDetails(response.responsePacket);
                setShowGameLogModal(true);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const playerStatus = (player) => {
        if (player.playerStatus === "KnockOut") {
            if (player.autoPlayCount === 3) {
                return `${player.playerStatus}(Timed Out)`;
            } else {
                return `${player.playerStatus}(Leaved Table)`;
            }
        } else {
            return player.playerStatus;
        }
    }

    const gameLogModal = () => {
        return (
            <Modal className="tablerowmodal" show={showGameLogModal} onHide={() => setShowGameLogModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body>
                    <div className="border-bottom d-flex justify-content-between align-items-center pb-3">
                        <h4 className="m-0">Session Details</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowGameLogModal(false)}></button>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Session ID</th>
                                    <td>{sessionDetails.sessionId}</td>

                                    <th>Total Players</th>
                                    <td>{sessionDetails.totalPlayerCount}</td>
                                </tr>

                                <tr>
                                    <th>Started At</th>
                                    <td>{dateUtilities.formattedDate(sessionDetails.startDateTime, "dd-mm-yyyy hh:mm aa")}</td>

                                    <th>Ended At</th>
                                    <td>{dateUtilities.formattedDate(sessionDetails.endDateTime, "dd-mm-yyyy hh:mm aa")}</td>
                                </tr>

                                <tr>
                                    <td colSpan={4}>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <b>Total Bet </b>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {sessionDetails.totalBetAmount.toFixed(2)}
                                            </div>
                                            <div>
                                                <b>Total Win </b>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {sessionDetails.totalWinAmount.toFixed(2)}
                                            </div>
                                            <div>
                                                <b>Total Fee </b>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {sessionDetails.totalFeeAmount.toFixed(2)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Table bordered striped>
                            <thead>
                                <tr>
                                    <th>Player</th>
                                    <th>Bet</th>
                                    <th>Win/Loose</th>
                                    <th>Position</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sessionDetails?.gameSessionPlayerList?.map((player, i) => (
                                    <tr key={i}>
                                        <td>
                                            {getPlayerSessionHistoryLink(player)}<br />
                                            {`+91 ${player?.userName}`}
                                        </td>
                                        <td>{player.totalBetAmount.toFixed(2)}</td>
                                        <td>{player.totalWinAmount.toFixed(2)}</td>
                                        <td>{player.winningPosition}</td>
                                        <td>{playerStatus(player)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="gameSessionLogs">
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Player</th>
                                        <th>Action</th>
                                        <th>Dice Number</th>
                                        <th>Token Index</th>
                                        <th>Start Position</th>
                                        <th>End Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sessionDetails?.gameSessionTurnLogList?.map((player, i) => (
                                        <tr key={i}>
                                            <td>
                                                {player.playerVisibleName}
                                            </td>
                                            <td>{player.action}</td>
                                            <td>{player.diceNumber}</td>
                                            <td>{player.tokenIndex}</td>
                                            <td>{player.startPosition}</td>
                                            <td>{player.endPosition}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <PaginationDataTable
                paginationUrl={props.forPlayer ? Player_Ludo_Game_Session_List_POST.replaceAll("{playerID}", props.playerID) :
                    props.AgentPlayer ? Agent_Player_Ludo_Game_Session_List_POST.replaceAll("{recordId}", props.playerID) : Ludo_Game_Session_List_POST}
                serverSidePagination={true}
                search={true}
                // pagination={true}
                columns={columns}
            />
            {showGameLogModal && gameLogModal()}
        </>
    );
};

export default GameSession;
