import axios from "axios";
import { SYSTEM_ERROR } from "../Config/CONSTANT";
import { FILE_UPLOAD_URL } from "../CONSTANT";
import Cookies from "universal-cookie";
import { Add_New_Player_Level_POST, GET_Player_Level_GET, UPDATE_Player_Level_PUT } from "../../api/ApiConst";

const cook = new Cookies().get('loginData');

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}
export const uploadPlayerLevelIcon = (data) => {
    
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_UPLOAD_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in uploadAvatarImage axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addPlayerLevel = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Add_New_Player_Level_POST, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addPlayerLevel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updatePlayerLevel = (id, data) => {
    
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(UPDATE_Player_Level_PUT.replaceAll("{id}", id), data, { headers: headersdata })
                .then((res) => {
                    
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerLevel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });

};

export const getPlayerLevelDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(GET_Player_Level_GET.replaceAll("{id}", id), { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetail axios");
                })
        } catch (error) {
            reject(SYSTEM_ERROR)

        }
    });
};
