import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import PageHeading from "../../Components/Common/PageHeading.jsx";

import { getSuperAdminSettingsList, getSuperAdminSettingDetails, updateSuperAdminSettingKeyValue, uploadFile, updateSuperAdminUrlSettingKeyValue, getSuperAdminUrlList } from "../..//Services/MasterServices/SettingService.js";
const Settings = () => {
    const settingValueKeyValuePairList = [
        { value: "ON", label: "ON", },
        { value: "OFF", label: "OFF", },
    ];
    const [list, setList] = useState(null);
    const [show, setShow] = useState(false);
    const [settingDetails, setSettingDetails] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [showLoader, setShowLoader] = useState(false);
    const [selectedSettingValue, setSelectedSettingValue] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const [bodyFormFileData, setBodyFormFileData] = useState("");
    const [urlList, setUrlList] = useState([]);

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const getSettingList = () => {
        getSuperAdminSettingsList().then((res) => {
            if (res.errorCode === 0) {
                setList(res.responsePacket);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const getUrlList = () => {
        getSuperAdminUrlList().then((res) => {
            if (res.errorCode === 0) {
                setUrlList(res.responsePacket);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleEditSettings = (id) => {
        getSuperAdminSettingDetails(id).then((res) => {
            if (res.errorCode === 0) {
                handleOpen();
                const _settingValue = settingValueKeyValuePairList.find((obj) => obj.value === res.responsePacket.settingValue);
                let dict = {
                    ...res.responsePacket,
                    settingValue: _settingValue
                };
                setSettingDetails(dict);
                setSelectedSettingValue(dict?.settingValue);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };
    const handleSettingValue = (e) => {
        setSettingDetails({
            ...settingDetails,
            settingValue: e.target.value,
        });
    };

    const handleUpdate = (id, pSettingValue) => {
        let request = {
            // settingValue: pSettingValue !== null ? pSettingValue : settingDetails?.settingValue,
            settingValue: selectedSettingValue.value
        };
        // return;
        updateSuperAdminSettingKeyValue(id, request).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleClose();
                getSettingList();
                setShowLoader(false);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                setShowLoader(false);
            }
        });
    };

    const handleSuperAdminSettingUpdate = (id, pSettingValue) => {
        let request = {
            settingValue: pSettingValue
        };

        updateSuperAdminUrlSettingKeyValue(id, request).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getUrlList();
                setShowLoader(false);
                setBodyFormFileData("");
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                setShowLoader(false);
                setBodyFormFileData("");
            }
        });
    };

    const handleApkUpload = (e) => {
        let file = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", file);
        setBodyFormFileData(bodyFormData);
    };
    const handleSetApkUpload = () => {
        if (bodyFormFileData) {
            setShowLoader(true);
            uploadFile(bodyFormFileData).then((res) => {
                if (res.errorCode === 0) {
                    handleSuperAdminSettingUpdate("NewAPKUrl", res?.responsePacket);
                }
            });
        }
    };
    const handleSetZipUpload = () => {
        if (bodyFormFileData) {
            setShowLoader(true);
            uploadFile(bodyFormFileData).then((res) => {
                if (res.errorCode === 0) {
                    handleSuperAdminSettingUpdate("NewZipUrl", res?.responsePacket);
                }
            });
        }
    };
    useEffect(() => {
        getSettingList();
        getUrlList();
    }, []);

    const handleInputChange = (e) => {
        const { name, value, id } = e.target;
        let updatedSettings = urlList.map(setting => {
            if (setting.settingKey === name) {
                return {
                    ...setting,
                    settingValue: value
                };
            }
            return setting;
        });
        setUrlList(updatedSettings);
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    {showLoader ? (
                        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
                            <div className="spinner-border m-5 " role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <PageHeading heading={"Super Admin Settings"} />
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="card">
                                        <div className="card-body">
                                            <form id="configform">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="formrow-inputZip" className="form-label">
                                                                Upload New APK File
                                                            </label>
                                                            <div className="d-flex">
                                                                <input type="file" className="form-control" accept=".apk" onChange={(e) => handleApkUpload(e)} />
                                                                <button type="button" className="btn btn-primary w-md" onClick={() => handleSetApkUpload()}>Upload</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <form id="configform">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="formrow-inputZip" className="form-label">
                                                                Upload New Zip File
                                                            </label>
                                                            <div className="d-flex">
                                                                <input type="file" className="form-control" accept=".zip,.rar,.7zip" onChange={(e) => handleApkUpload(e)} />
                                                                <button type="button" className="btn btn-primary w-md" onClick={() => handleSetZipUpload()}>Upload</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {urlList &&
                                        urlList.map((data, i) => {
                                            return (
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="mb-3">
                                                                    <label htmlFor="formrow-inputZip" className="form-label">
                                                                        {data?.settingKey}
                                                                    </label>
                                                                    <textarea type="text" className="form-control"
                                                                        name={data?.settingKey} value={data?.settingValue}
                                                                        onChange={(e) => handleInputChange(e)}></textarea>
                                                                    <button type="button" className="btn btn-primary w-md"
                                                                        onClick={() => handleSuperAdminSettingUpdate(data?.settingKey, data?.settingValue)}
                                                                    >Update</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>

                                <div className="col-lg-7">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Module Settings</h4>
                                            <div className="table-responsive">
                                                <table className="table mb-0 w-100">
                                                    <thead>
                                                        <tr>
                                                            <th>Setting Key</th>
                                                            <th>Setting Value</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {list &&
                                                            list.map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{data?.settingKey}</td>
                                                                        <td style={{ maxWidth: "360px" }}>{data?.settingValue}</td>
                                                                        <td>
                                                                            <a onClick={() => handleEditSettings(data?.settingKey)} className="btn btn-primary">
                                                                                Edit
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {/* end row */}
                    {/* end row */}
                </div>{" "}
                {/* container-fluid */}
            </div>
            <Modal className="tablerowmodal" show={show} onHide={() => handleClose()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Settings Update</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Settings Key
                                        </label>
                                        <input type="text" className="form-control" id="formrow-email-input" placeholder="Enter Full Name" value={settingDetails?.settingKey} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Setting Value
                                        </label>
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Settings Value"
                                            value={settingDetails?.settingValue}
                                            onChange={(e) => handleSettingValue(e)}
                                        /> */}
                                        <Select
                                            options={settingValueKeyValuePairList}
                                            value={selectedSettingValue}
                                            onChange={(e) => { setSelectedSettingValue(e) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={() => handleUpdate(settingDetails?.settingKey, null)}>
                                    Update
                                </a>
                                &nbsp; &nbsp;
                                <a className="btn btn-primary w-md" onClick={() => handleClose()}>
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Settings;
