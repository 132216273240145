import React, { useMemo, useRef, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { DateRangePicker } from 'react-date-range';
import { Link } from 'react-router-dom';
import { enGB } from 'date-fns/locale';

import { Filter_Player_By_Deposit_Count_POST, Filter_Player_By_Game_Count_POST, Spin_Player_By_Game_Spin_Count_POST } from '../api/ApiConst';

import PageHeading from './Common/PageHeading'
import dateUtilities from '../utilities/DateUtilities'
import PaginationDataTable from './Common/PaginationDataTable'
import ConfirmationDialoge from './Common/ConfirmationDialoge';

const AdminPlayerCountReport = () => {
    const [record, setRecord] = useState({ gameCount: 1, depositCount: 1 });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [drawCount, setDrawCount] = useState(0);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [showGameDateRangePicker, setShowGameDateRangePicker] = useState(false);
    const [showSpinDateRangePicker, setSpinGameDateRangePicker] = useState(false);

    const [dateRange, setDateRange] = useState([{
        startDate: new Date(), endDate: new Date(), key: 'selection',
    }]);
    const [gameDateRange, setGameDateRange] = useState([{
        startDate: new Date(), endDate: new Date(), key: 'selection',
    }]);
    const [spinDateRange, setSpinDateRange] = useState([{
        startDate: new Date(), endDate: new Date(), key: 'selection',
    }]);
    const [depositDetails, setDepositDetails] = useState({
        count: 1,
        timeObj: { fromDateTimeStamp: (new Date()).getTime(), toDateTimeStamp: (new Date()).getTime() },
    });

    const [gameDetails, setGameDetails] = useState({
        count: 1,
        timeObj: { fromDateTimeStamp: (new Date()).getTime(), toDateTimeStamp: (new Date()).getTime() },
    });

    const [spinCountDetails, setSpinCountDetails] = useState({
        timeObj: { fromDateTimeStamp: (new Date()).getTime(), toDateTimeStamp: (new Date()).getTime() },
    });

    const dateRangePickerRef = useRef(null);
    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleDays = (count) => {
        if (count === "deposit") {
            if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
                setDateRange([{
                    startDate: dateRange[0].startDate,
                    endDate: dateRange[0].endDate,
                    key: 'selection',
                }]);
            }
            setShowDateRangePicker(!showDateRangePicker);
        } else if (count === "spin") {
            if (spinDateRange[0]?.startDate && spinDateRange[0]?.endDate) {
                setSpinDateRange([{
                    startDate: spinDateRange[0].startDate,
                    endDate: spinDateRange[0].endDate,
                    key: 'selection',
                }]);
            }
            setSpinGameDateRangePicker(!showSpinDateRangePicker);

        } else {
            if (gameDateRange[0]?.startDate && gameDateRange[0]?.endDate) {
                setGameDateRange([{
                    startDate: gameDateRange[0].startDate,
                    endDate: gameDateRange[0].endDate,
                    key: 'selection',
                }]);
            }
            setShowGameDateRangePicker(!showGameDateRangePicker);
        }
    };

    const handleDateRange = (ranges, count) => {
        if (count === "deposit") {
            const selectedDateRange = ranges?.selection;
            if (selectedDateRange && (selectedDateRange.endDate - selectedDateRange.startDate) <= (30 * 24 * 60 * 60 * 1000)) {
                setDateRange([{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    key: 'selection',
                }]);
            } else {
                toast.warn("Please select a date range of 30 days or less", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else if (count === "spin") {
            const selectedDateRange = ranges?.selection;
            if (selectedDateRange && (selectedDateRange.endDate - selectedDateRange.startDate) <= (30 * 24 * 60 * 60 * 1000)) {
                setSpinDateRange([{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    key: 'selection',
                }]);
            } else {
                toast.warn("Please select a date range of 30 days or less", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            const selectedDateRange = ranges?.selection;
            if (selectedDateRange && (selectedDateRange.endDate - selectedDateRange.startDate) <= (30 * 24 * 60 * 60 * 1000)) {
                setGameDateRange([{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    key: 'selection',
                }]);
            } else {
                toast.warn("Please select a date range of 30 days or less", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };
    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prev) => ({ ...prev, [id]: value }))
    }
    const validateAddUpdateAssets = () => {
        if (record.depositCount === "" || record.gameCount === "") {
            toast.warn("Count cannot be empty ", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    };

    const handleSubmit = (count) => {
        if (!validateAddUpdateAssets()) {
            return;
        }
        // return;
        if (count === "deposit") {
            const fromDate = (new Date(dateRange[0]?.startDate.toDateString())).getTime();
            const toDate = (new Date(dateRange[0]?.endDate.toDateString())).getTime();
            const fromDateIst = dateUtilities.timestampToIst(fromDate);
            const toDateIst = dateUtilities.timestampToIst(toDate);
            const timeObj = {
                fromDateTimeStamp: fromDateIst,
                toDateTimeStamp: toDateIst,
            };
            const _depositDetails = {
                count: record?.depositCount,
                timeObj: timeObj
            }
            setDepositDetails(_depositDetails);
            setShowDateRangePicker(false);
            handleRefresh();
        } else if (count === "spin") {
            const fromDate = (new Date(spinDateRange[0]?.startDate.toDateString())).getTime();
            const toDate = (new Date(spinDateRange[0]?.endDate.toDateString())).getTime();
            const fromDateIst = dateUtilities.timestampToIst(fromDate);
            const toDateIst = dateUtilities.timestampToIst(toDate);
            const timeObj = {
                fromDateTimeStamp: fromDateIst,
                toDateTimeStamp: toDateIst,
            };
            const _spinDetails = {
                timeObj: timeObj
            }
            setSpinCountDetails(_spinDetails);
            setSpinGameDateRangePicker(false);
            handleRefresh();
        } else {
            const fromDate = (new Date(gameDateRange[0]?.startDate.toDateString())).getTime();
            const toDate = (new Date(gameDateRange[0]?.endDate.toDateString())).getTime();
            const fromDateIst = dateUtilities.timestampToIst(fromDate);
            const toDateIst = dateUtilities.timestampToIst(toDate);
            const timeObj = {
                fromDateTimeStamp: fromDateIst,
                toDateTimeStamp: toDateIst,
            };
            const _gameDetails = {
                count: record?.gameCount,
                timeObj: timeObj
            }
            setGameDetails(_gameDetails);
            setShowGameDateRangePicker(false);
            handleRefresh();
        }
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };
    const columns = useMemo(
        () => [{
            name: "Mobile No.",
            selector: (row) => (
                <Link to={"/playerDetail?rId=" + row?.playerId} target="_blank" key="t-adminUserDetail">
                    {row?.mobileNumber}
                </Link>
            ),
            sortable: true,
            // right: true,
            reorder: true,
        }, {
            name: "Deposit Count",
            selector: (row) => row?.depositCount,
            sortable: true,
            // right: true,
            reorder: true,
        }, {
            name: "Deposit Total",
            selector: (row) => row?.depositTotal,
            sortable: true,
            // right: true,
            reorder: true,
        },
        ],
        [depositDetails]
    );
    const gameColumns = useMemo(
        () => [
            {
                name: "Mobile No.",
                selector: (row) => (
                    <Link to={"/playerDetail?rId=" + row?.playerId} target="_blank" key="t-adminUserDetail">
                        {row?.mobileNumber}
                    </Link>
                ),
                sortable: true,
                // right: true,
                // reorder: true,
            }, {
                name: "Game Count",
                selector: (row) => row?.gameCount,
                sortable: true,
                // right: true,
                reorder: true,
            },],
        [gameDetails]
    );

    const spinColumns = useMemo(
        () => [
            {
                name: "Bet Amount",
                selector: (row) => row?.betAmount.toFixed(2),
                sortable: true,
                right: true,
                reorder: true,
                width: "120px"
            },
            {
                name: "Game Count",
                selector: (row) => row?.gameCount,
                sortable: true,
                right: true,
                reorder: true,
                width: "130px"
            },
            {
                name: "3X",
                selector: (row) => row?.threeXCount,
                sortable: true,
                right: true,
                reorder: true,

            },
            {
                name: "4X",
                selector: (row) => row?.fourXCount,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "6X",
                selector: (row) => row?.sixXCount,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "10X",
                selector: (row) => row?.tenXCount,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "100X",
                selector: (row) => row?.hundredXCount,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "1000X",
                selector: (row) => row?.thousandXCount,
                sortable: true,
                right: true,
                reorder: true,
            },
        ],
        [spinCountDetails]
    );

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Count List"} />
                    <div className="row">
                        <div className="col-xl-6">
                            {/* <div className=" d-flex justify-content-end  mb-2 ">
                                <input type="number"
                                    className="form-control w-25"
                                    id="depositCount"
                                    min={0}
                                    placeholder="Enter Count"
                                    value={record?.depositCount} onChange={handleTextChange}
                                />
                                <button className='btn  border-2  border-dark bg-white mx-2'
                                    onClick={() => handleDays("deposit")}
                                >
                                    {dateUtilities.formattedDate(dateRange[0]?.startDate, "dd-MM-yyyy")} &nbsp; --  &nbsp;{
                                        dateUtilities.formattedDate(dateRange[0]?.endDate, "dd-MM-yyyy")}
                                </button>
                                <button className=' bg-primary text-white border-0  px-3 rounded-2 '
                                    onClick={() => handleSubmit("deposit")}
                                >Submit</button>
                            </div> */}
                            <div className='customDatePikerCount '>
                                <div className=' position-relative '>

                                    {showDateRangePicker && (
                                        <>
                                            {/* TODO > Doubts here > ranges */}
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                className=''
                                                ranges={dateRange}
                                                onChange={(ranges) => {
                                                    handleDateRange(ranges, "deposit");
                                                }}
                                                locale={enGB}
                                                style={{ top: '100px', left: '50px' }}
                                            >
                                            </DateRangePicker>
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* <div className="card cstmPlayerCount">
                                <div className="card-body pt-1">
                                    <div className='d-flex justify-content-between position-absolute' style={{ zIndex: "1", top: "25px" }}>
                                        <h4 className="card-title">Deposit Count</h4>
                                    </div>
                                    <PaginationDataTable
                                        drawCount={drawCount}
                                        paginationUrl={Filter_Player_By_Deposit_Count_POST.replaceAll("{count}", depositDetails?.count)}
                                        serverSidePagination={false}
                                        search={false}
                                        columns={columns}
                                        filter={depositDetails.timeObj}
                                        exportCSV={true}
                                        filename={"deposit_count"}
                                        dateRange={dateRange}
                                    />
                                </div>
                            </div> */}
                        </div>
                        <div className="col-xl-6 ">
                            <div className=" d-flex justify-content-end  mb-2 ">
                                <input type="number"
                                    className="form-control w-25"
                                    id="gameCount"
                                    placeholder="Enter Count"
                                    min={0}
                                    value={record?.gameCount} onChange={handleTextChange}
                                />
                                <button className='btn  border-2  border-dark bg-white mx-2'
                                    onClick={() => handleDays("game")}
                                >
                                    {dateUtilities.formattedDate(gameDateRange[0]?.startDate, "dd-MM-yyyy")} &nbsp; --  &nbsp;{
                                        dateUtilities.formattedDate(gameDateRange[0]?.endDate, "dd-MM-yyyy")}
                                </button>
                                <button className=' bg-primary text-white border-0  px-3 rounded-2 '
                                    onClick={() => handleSubmit("game")}
                                >Submit</button>
                            </div>
                            <div className='customDatePikerCount '>
                                <div className=' position-relative '>

                                    {showGameDateRangePicker && (
                                        <>
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                className=''
                                                ranges={gameDateRange}
                                                onChange={(ranges) => {
                                                    handleDateRange(ranges, "game");
                                                }}
                                                locale={enGB}
                                                style={{ top: '100px', left: '50px' }}
                                            >
                                            </DateRangePicker>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="card cstmPlayerCount">
                                <div className="card-body pt-1 ">
                                    <div className='d-flex justify-content-between position-absolute' style={{ zIndex: "1", top: "25px" }}>
                                        <h4 className="card-title">Game Count</h4>
                                    </div>
                                    <PaginationDataTable
                                        drawCount={drawCount}
                                        paginationUrl={Filter_Player_By_Game_Count_POST.replaceAll("{count}", gameDetails?.count)}
                                        serverSidePagination={false}
                                        search={false}
                                        columns={gameColumns}
                                        filename={"game_count"}
                                        filter={{
                                            fromDateTimeStamp: gameDetails?.timeObj.fromDateTimeStamp,
                                            toDateTimeStamp: gameDetails?.timeObj.toDateTimeStamp
                                        }}
                                        exportCSV={true}
                                        gameDateRange={gameDateRange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div className=" d-flex justify-content-end  mb-2 ">
                                <button className='btn  border-2  border-dark bg-white mx-2'
                                    onClick={() => handleDays("spin")}
                                >
                                    {dateUtilities.formattedDate(spinDateRange[0]?.startDate, "dd-MM-yyyy")} &nbsp; --  &nbsp;{
                                        dateUtilities.formattedDate(spinDateRange[0]?.endDate, "dd-MM-yyyy")}
                                </button>
                                <button className=' bg-primary text-white border-0  px-3 rounded-2 '
                                    onClick={() => handleSubmit("spin")}
                                >Submit</button>
                            </div>
                            <div className='customDatePikerCount '>
                                <div className=' position-relative '>

                                    {showSpinDateRangePicker && (
                                        <>
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                className=''
                                                ranges={spinDateRange}
                                                onChange={(ranges) => {
                                                    handleDateRange(ranges, "spin");
                                                }}
                                                locale={enGB}
                                                style={{ top: '100px', left: '50px' }}
                                            >
                                            </DateRangePicker>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="card cstmPlayerCount">
                                <div className="card-body pt-1 ">
                                    <div className='d-flex justify-content-between position-absolute' style={{ zIndex: "1", top: "25px" }}>
                                        <h4 className="card-title">Spin Count</h4>
                                    </div>
                                    <PaginationDataTable
                                        drawCount={drawCount}
                                        paginationUrl={Spin_Player_By_Game_Spin_Count_POST}
                                        serverSidePagination={false}
                                        search={false}
                                        columns={spinColumns}
                                        filename={"spin_count"}
                                        spinDateRange={spinDateRange}
                                        filter={{
                                            fromDateTimeStamp: spinCountDetails?.timeObj.fromDateTimeStamp,
                                            toDateTimeStamp: spinCountDetails?.timeObj.toDateTimeStamp
                                        }}
                                        exportCSV={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AdminPlayerCountReport