import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Download_Deposit_Excel_POST, Player_GetPlayerUuidByPlayerId, Withdraw_History_Excel_POST, Withdraw_Request_History_Details_GET, Withdraw_Request_History_POST } from "../api/ApiConst.js";
import { callGetApi, callPostApiForExcelDownLoad } from "../api/ApiCaller.js";
import PaginationDataTable from "./Common/PaginationDataTable.jsx";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select"
import dateUtilities from "../utilities/DateUtilities.jsx";
import PageHeading from "./Common/PageHeading.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomButton from "./Common/CustomButton.jsx";
import { DateRangePicker } from 'react-date-range';
import { enGB } from 'date-fns/locale';
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";

const filterOptions = [
    {
        value: "All",
        label: "All",
    },
    {
        value: "Rejected",
        label: "Rejected",
    },
    {
        value: "Approved",
        label: "Approved",
    },
    {
        value: "Completed",
        label: "Completed",
    },
    {
        value: "Failed",
        label: "Failed",
    },
]
const modalFilterOptions = [
    {
        value: "All",
        label: "All",
    },
    {
        value: "Rejected",
        label: "Rejected",
    },
    {
        value: "Approved",
        label: "Approved",
    },
    {
        value: "Completed",
        label: "Completed",
    },
    {
        value: "Failed",
        label: "Failed",
    },
]

const WithdrawHistory = () => {
    const { filterType } = useParams();
    const navigate = useNavigate();

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [details, setDetails] = useState(null);

    const [filter, setFilter] = useState("");
    const dateRangePickerRef = useRef(null);
    const [showExportModal, setShowExportModal] = useState(false);
    const [dateRange, setDateRange] = useState([{
        startDate: new Date(), endDate: new Date(), key: 'selection',
    }]);

    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [modalFilter, setModalFilter] = useState(modalFilterOptions[0]);

    useEffect(() => {
        if (filterType) {
            if (filterType === "Rejected") {
                setFilter(filterOptions[1]);
            } else if (filterType === "Approved") {
                setFilter(filterOptions[2]);
            } else if (filterType === "Completed") {
                setFilter(filterOptions[3]);
            } else if (filterType === "Failed") {
                setFilter(filterOptions[4]);
            }
        } else {
            setFilter(filterOptions[0]);
        }
    }, [filterType]);

    useEffect(() => {
        if (!showDetailsModal) {
            setDetails(null);
        }
    }, [showDetailsModal]);

    const handleDateRange = (ranges) => {
        const selectedDateRange = ranges?.selection;
        if (selectedDateRange && (selectedDateRange.endDate - selectedDateRange.startDate) <= (30 * 24 * 60 * 60 * 1000)) {
            setDateRange([{
                startDate: selectedDateRange.startDate,
                endDate: selectedDateRange.endDate,
                key: 'selection',
            }]);
        } else {
            toast.warn("Please select a date range of 30 days or less", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleExportClick = () => {
        setShowExportModal(true);
        setShowDateRangePicker(true);
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSubmit = () => {
        window.showParentLoader();
        setShowSpinner(true);
        const fromDate = (new Date(dateRange[0]?.startDate.toDateString())).getTime();
        const toDate = (new Date(dateRange[0]?.endDate.toDateString())).getTime();
        const fromDateIst = dateUtilities.timestampToIst(fromDate);
        const toDateIst = dateUtilities.timestampToIst(toDate);
        const timeObj = {
            fromDateTimeStamp: fromDateIst,
            toDateTimeStamp: toDateIst,
            txnStatus: modalFilter.value
        };
        callPostApiForExcelDownLoad(Withdraw_History_Excel_POST, timeObj, {}, (response) => {
            // if (response === 0) {
            let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a URL from the Blob
            let url = window.URL.createObjectURL(blob);

            // Create an anchor element and trigger a download
            let link = document.createElement('a');
            link.href = url;

            let currentTimeStamp = new Date().getTime();
            let fileName = `withdraw_history` +
                `_${dateUtilities.formattedDate(timeObj.fromDateTimeStamp, "ddMMMyyyy")}` +
                `_${dateUtilities.formattedDate(timeObj.toDateTimeStamp, "ddMMMyyyy")}` +
                `_${dateUtilities.formattedDate(currentTimeStamp, "ddMMMyyyy")}` +
                `_${dateUtilities.formatTimeStamp(currentTimeStamp)}` +
                `.csv`;

            link.setAttribute('download', fileName); // or any other extension
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            handleSuccessToast("CSV file exported successfully.");
            setShowDateRangePicker(false);
            setShowExportModal(false);
            window.hideParentLoader();
        }
            // }
        );
    };

    const transactionDetailsModal = () => {
        return (
            showDetailsModal && (
                <Modal className="tablerowmodal" show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className="border-bottom d-flex justify-content-between align-items-center pb-3">
                            <h4 className="m-0">Withdraw History Details</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsModal(false)}></button>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Transaction ID:</span>
                                <b className="ms-2">{details?.transactionId}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Withdraw Transaction Status:</span>
                                <b className="ms-2">{details?.withdrawTransactionStatus}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Amount:</span>
                                <b className="ms-2">{details?.amount.toFixed(2)}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Account Type:</span>
                                <b className="ms-2">{details?.accountType}</b>
                            </div>
                            {details?.transferType === "UPI" ? (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>UPI ID:</span>
                                    <b className="ms-2">{details?.upiId}</b>
                                </div>
                            ) : details?.transferType === "CRYPTO" ? (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Crypto Wallet Address:</span>
                                    <b className="ms-2">{details?.cryptoWalletAddress}</b>
                                </div>
                            ) : (
                                <>
                                    <div className="d-flex justify-content-between font-size-13 mb-2">
                                        <span>Bank Name:</span>
                                        <b className="ms-2">{details?.bankName}</b>
                                    </div>
                                    <div className="d-flex justify-content-between font-size-13 mb-2">
                                        <span>Account Number:</span>
                                        <b className="ms-2">{details?.accountNumber}</b>
                                    </div>
                                    <div className="d-flex justify-content-between font-size-13 mb-2">
                                        <span>Account Holder Name:</span>
                                        <b className="ms-2">{details?.accountHolderName}</b>
                                    </div>
                                    <div className="d-flex justify-content-between font-size-13 mb-2">
                                        <span>IFSC Code:</span>
                                        <b className="ms-2">{details?.ifscCode}</b>
                                    </div>
                                </>
                            )}
                            {details?.payTmMobileNumber && details?.payTmMobileNumber !== "" && (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>PayTm Mobile Number:</span>
                                    <b className="ms-2">{details?.payTmMobileNumber}</b>
                                </div>
                            )}
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Transfer Type:</span>
                                <b className="ms-2">{details?.transferType}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Convenience Fee:</span>
                                <b className="ms-2">{details?.convenienceFee.toFixed(2)}</b>
                            </div>
                            {details?.remark && details?.remark !== "" && (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Remark:</span>
                                    <b className="ms-2">{details?.remark}</b>
                                </div>
                            )}
                            {details?.thirdPartyReferenceId && details?.thirdPartyReferenceId !== "" && (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Third Party Reference Id:</span>
                                    <b className="ms-2">{details?.thirdPartyReferenceId}</b>
                                </div>
                            )}
                            {details?.thirdPartyTransactionId && details?.thirdPartyTransactionId !== "" && (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Third Party Transaction Id:</span>
                                    <b className="ms-2">{details?.thirdPartyTransactionId}</b>
                                </div>
                            )}
                            {details?.refundTransactionId && details?.refundTransactionId !== "" && (
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Refund Transaction Id:</span>
                                    <b className="ms-2">{details?.refundTransactionId}</b>
                                </div>
                            )}
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Player Mobile:</span>
                                <b className="ms-2">{("+91 " + details?.playerMobile) ?? ""}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Pan Card Number:</span>
                                <b className="ms-2">{details?.panCardNumber ?? ""}</b>
                            </div>
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Pan Card Name:</span>
                                <b className="ms-2">{details?.panCardName ?? ""}</b>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const openPlayerDetail = (id) => {
        let url = Player_GetPlayerUuidByPlayerId.replaceAll("{playerId}", id);
        callGetApi(url, {}, (response) => {
            window.open("/playerDetail?rId=" + response?.responsePacket);
        }, (error) => {
            console.log(error);
        });
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.withdrawTransactionStatus === "Pending") {
                        return <div className="led-yellow" title="Pending"></div>;
                    } else if (row.withdrawTransactionStatus === "Approved") {
                        return <div className="led-green" title="Approved"></div>;
                    } else {
                        return <div className="led-red" title="Rejected"></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Player Mobile",
                selector: (row) => {
                    if (row.playerMobile === "" || row.playerMobile === undefined) {
                        return <></>;
                    } else {
                        return (
                            <span
                                onClick={() => openPlayerDetail(row.playerId)}
                                style={{ color: '#556ee6', cursor: 'pointer' }}
                            >
                                {"+91 " + row.playerMobile}
                            </span>
                        );
                    }
                },
                sortable: true,
                reorder: true,
            },
            {
                name: "Pan Card Name",
                selector: (row) => row?.panCardName ?? "",
                sortable: true,
                reorder: true,
                width: "200px"
            },
            {
                name: "Pan Card Number",
                selector: (row) => row?.panCardNumber ?? "",
                sortable: true,
                reorder: true,
            },
            {
                name: "Transaction ID",
                selector: (row) => row.transactionRefId,
                sortable: true,
                reorder: true,
            },
            {
                name: "Transaction Date",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
                sortable: true,
                reorder: true,
            },
            {
                name: "Account Type",
                selector: (row) => row.accountType,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Amount",
                selector: (row) => row.amount?.toFixed(2),
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Transfer Type",
                selector: (row) => row.transferType,
                sortable: true,
                right: true,
                reorder: true,
            },
            // {
            //     name: 'Wallet Address',
            //     selector: row => row.cryptoWalletAddress,
            //     sortable: true,
            //     right: true,
            //     reorder: true,
            // },

            // {
            //     name: 'Update At',
            //     selector: (row) => moment(row.modifiedAt).format('DD-MM-YYYY HH:MM A'),
            //     sortable: true,
            //     right: true,
            //     reorder: true,
            // },
            {
                id: "action",
                name: "",
                selector: (row) => {
                    return actionButtons(row);
                },
                width: "80px",
                sortable: false,
            },
        ],
        [filterType]
    );

    //Action Buttons
    const actionButtons = useCallback((row) => {
        return (
            <>
                <span className="btn btn-primary mx-1 py-1 px-2 font-size-16" onClick={() => handleViewDetails(row.recordId)}>
                    <i className="bx bx-show" />
                </span>
            </>
        );
    }, []);

    const handleViewDetails = (recordId) => {
        let url = Withdraw_Request_History_Details_GET.replaceAll("{withdrawRequestId}", recordId);
        callGetApi(
            url,
            {},
            (response) => {
                setDetails(response.responsePacket);
                setShowDetailsModal(true);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const handleDays = (event) => {
        event.preventDefault();
        if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
            setDateRange([{
                startDate: dateRange[0].startDate,
                endDate: dateRange[0].endDate,
                key: 'selection',
            }]);
        }
        setShowDateRangePicker(!showDateRangePicker);
        return false;
    }

    const exportExcelModal = () => {
        return (
            showExportModal && (
                <Modal className="tablerowmodal customModalSize" show={showExportModal} onHide={() => setShowExportModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header>
                        <h4 className="m-0">Export Csv</h4>
                        <Button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowExportModal(false)}></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row">
                                <div className="col-12 mx-auto" style={{ zIndex: "9" }}>
                                    <div className="custom_dropdown position-relative me-2 top-0 w-100" style={{ left: "0", }}>
                                        <Select
                                            options={modalFilterOptions}
                                            value={modalFilter}
                                            onChange={(e) => {
                                                setModalFilter(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <button className='btn border-2 border-dark bg-white w-100'

                                        onClick={(event) => handleDays(event)}
                                    >
                                        {dateUtilities.formattedDate(dateRange[0]?.startDate, "dd-MM-yyyy")} &nbsp; --  &nbsp;{
                                            dateUtilities.formattedDate(dateRange[0]?.endDate, "dd-MM-yyyy")}
                                    </button>
                                    <div className='customDatePikerCount position-relative mt-3' style={{ left: "0" }}>
                                        <div className=' position-relative'>
                                            {showDateRangePicker && (
                                                <>
                                                    <DateRangePicker
                                                        ref={dateRangePickerRef}
                                                        className=''
                                                        ranges={dateRange}
                                                        onChange={(ranges) => {
                                                            handleDateRange(ranges);
                                                        }}
                                                        locale={enGB}
                                                        style={{ top: '100px', left: '50px' }}
                                                    >
                                                    </DateRangePicker>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className=' bg-primary text-white border-0 py-2 px-3 rounded-2 w-100 '
                            onClick={handleSubmit}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            )
        );
    };
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Withdraw History"} />
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3">
                                <div className="d-flex export-excel">
                                    <div className="custom_dropdown position-relative me-2 top-0" style={{ left: "0" }}>
                                        <Select
                                            options={filterOptions}
                                            value={filter}
                                            onChange={(e) => {
                                                e.value === "" ? navigate(`/withdraw-history`) : navigate(`/withdraw-history/${e.value}`);
                                                setFilter(e);
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "200px" }}>
                                        <CustomButton title="Export Csv" buttonClass="createButton" handleClick={handleExportClick} />
                                    </div>
                                </div>
                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <PaginationDataTable
                                        paginationUrl={Withdraw_Request_History_POST}
                                        serverSidePagination={true}
                                        search={true}
                                        columns={columns}
                                        filter={{ txnStatus: filter.value }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {transactionDetailsModal()}
            {exportExcelModal()}
        </>
    );
};

export default WithdrawHistory;
