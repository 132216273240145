import {Add_New_Admin_Question_Answer_POST, GET_Admin_Question_Answer_GET, PUT_Admin_Question_Answer_PUT, Delete_Admin_Question_Answer_DELETE, Update_Admin_Question_Answer_Revive_PUT, Activate_Admin_Question_Answer_PUT, Deactivate_Admin_Question_Answer_PUT, Get_Question_Answer_Category_List_POST } from "../../api/ApiConst.js";
import { SYSTEM_ERROR } from "../Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersData = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};


export const addQuestion = (data) => {
    return new Promise((resolve, reject) => {
        console.log(data);
        try {
            axios
                .post(Add_New_Admin_Question_Answer_POST, data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);

                    console.log(res,'Add_New_Admin_Question_Answer_POST')
                })
                .catch((err) => {
                    reject("Error in addQuestion axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getQuestionDetails = (id) => {
    return new Promise((resolve, reject) => {
        console.log(id);
        try {
            axios
                .get(GET_Admin_Question_Answer_GET.replaceAll("{uuid}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateQuestionDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PUT_Admin_Question_Answer_PUT.replaceAll("{uuid}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateQuestionDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteQuestion = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Admin_Question_Answer_DELETE.replaceAll("{uuid}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DeleteQuestion axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const reviveQuestion = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Update_Admin_Question_Answer_Revive_PUT.replaceAll("{uuid}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ReviveQuestion axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activateQuestion = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Activate_Admin_Question_Answer_PUT.replaceAll("{uuid}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ActivateQuestion axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateQuestion = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Deactivate_Admin_Question_Answer_PUT.replaceAll("{uuid}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Deactivate Question axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getCategories = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Get_Question_Answer_Category_List_POST, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Getting categories axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};